import React, { useRef, LegacyRef, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Tag } from 'src/slices/tag';
import { ManagedUser } from 'src/slices/auth';
import TeamCard from 'src/components/teams/teamCard';

const useStyles = createUseStyles({
  tagListItem: {
    display: 'flex',
    padding: '0px',
    width: '100%',
    height: '120px',
    background: 'rgba(237, 240, 255, 0.60)',
    borderRadius: '8px',
  },

  tagList: {
    listStyleType: 'none',
    scrollBehavior: 'smooth',
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    gap: '16px',
    display: 'flex',
    marginBottom: '16px',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 395px)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
});

interface Props {
  tag: Tag;
  managedUsers: ManagedUser[];
}

const TagsListCard: React.FC<Props> = ({ tag, managedUsers }) => {
  const classes = useStyles();
  const [test, setTest] = useState(tag);

  useEffect(() => {
    if (test === null) {
      setTest(tag);
    }
  }, [test]);
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <li
        className={classes.tagList}
        style={{ maxHeight: 'calc(100vh - 395px)' }}
        ref={ref as LegacyRef<HTMLLIElement>}
      >
        {managedUsers.map((managedUser, index) => (
          <React.Fragment key={index}>
            <div key={index} className={classes.tagListItem}>
              <TeamCard key={index} managedUser={managedUser} />
            </div>
          </React.Fragment>
        ))}
      </li>
    </div>
  );
};
export default TagsListCard;
