import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { User } from 'src/slices/auth';
import {
  SideBarHandleContext,
  SignatureSelectorHandleContext,
} from 'src/hooks/context';
import { Tag } from 'src/slices/tag';
import { ButtonBase } from '@mui/material';
import DOMPurify from 'dompurify';

const useStyles = createUseStyles({
  tagContainer: {
    width: '400px',
    height: '400px',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    boxShadow:
      '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24)',
  },
  signatureContainerPreview: {
    padding: '16px',
    fontFamily: 'Arial, sans-serif',
    width: '400px',
  },
  name: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  position: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '8px',
  },
  company: {
    fontSize: '14px',
    color: '#777',
    marginBottom: '8px',
  },
  contactInfo: {
    fontSize: '12px',
    color: '#333',
    marginTop: '16px',
  },
  phone: {
    display: 'block',
  },
  email: {
    display: 'block',
  },
  website: {
    display: 'block',
    color: '#0066cc',
    textDecoration: 'none',
  },
});

interface Props {
  user: User;
  tag: Tag;
}

const PreviewSignature: React.FC<Props> = ({ user, tag }) => {
  const classes = useStyles();
  const sidebarContext = useContext(SideBarHandleContext);
  const signatureSelectorContext = useContext(SignatureSelectorHandleContext);

  const openSignatureSelector = () => {
    signatureSelectorContext?.setTag(tag);
    sidebarContext?.setOpenSignatureSelector(true);
  };

  const renderWithUserData = (htmlString: string, user: User) => {
    return htmlString
      .replace(/{firstName}/g, user.firstName || '')
      .replace(/{lastName}/g, user.lastName || '')
      .replace(/{phoneNumber}/g, user.phoneNumber || '')
      .replace(/{profilePictureUrl}/g, user.profilePictureUrl || '')
      .replace(/{companyLogo}/g, user.companyLogo || '')
      .replace(/{email}/g, user.email || '')
      .replace(/{company}/g, user.company || '')
      .replace(/{position}/g, user.position || '')
      .replace(/{website}/g, user.website || '')
      .replace(/{facebook}/g, user.facebook || '')
      .replace(/{linkedin}/g, user.linkedin || '')
      .replace(/{twitter}/g, user.twitter || '')
      .replace(/{address}/g, user.address || '');
  };

  // Utilisation

  const personalizedHtml = tag.signature?.body
    ? renderWithUserData(tag.signature?.body, user)
    : '';

  const sanitizedHtml = DOMPurify.sanitize(personalizedHtml);

  return (
    <ButtonBase
      key={tag._id}
      className={classes.tagContainer}
      onClick={openSignatureSelector}
    >
      {tag.name}
      <div key={user._id} className={classes.signatureContainerPreview}>
        {tag.signature?.body ? (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        ) : (
          <></>
        )}
      </div>
    </ButtonBase>
  );
};

export default PreviewSignature;
