import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';
import { Tag } from './tag';

export interface Signature {
  id: string;
  body: string;
  model: number;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface CreateSignatureDto {
  body: string;
  model: number;
  tagId: string;
}

export interface UpdateSignatureDto {
  id: string;
  body?: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export const fetchAllSignature = createAsyncThunk(
  'signature/fetchAllSignature',
  async () => {
    const result = await apiRequest<Signature[]>('GET', '/signature');
    return result;
  },
);

export const fetchSignatureById = createAsyncThunk(
  'signature/fetchSignatureById',
  async (signatureId: string) => {
    const result: Signature = await apiRequest<Signature>(
      'GET',
      `/signature/${signatureId}`,
    );
    return result;
  },
);

export const addNewSignature = createAsyncThunk(
  'signature/addSignature',
  async (createSignatureDto: CreateSignatureDto) => {
    const results: { tag: Tag; signature: Signature } = await apiRequest<{
      tag: Tag;
      signature: Signature;
    }>('POST', '/signature/addSignature', undefined, {
      ...createSignatureDto,
    });
    return results;
  },
);

export const updateSignature = createAsyncThunk(
  'signature/updateSignature',
  async (updateSignatureDto: UpdateSignatureDto) => {
    const result = await apiRequest<Signature>(
      'PUT',
      '/signature/update',
      undefined,
      {
        ...updateSignatureDto,
      },
    );

    return result;
  },
);

export const deleteSignature = createAsyncThunk(
  'signature/deleteSignature',
  async (signatureId: string) => {
    await apiRequest<Signature>('DELETE', `/signature/${signatureId}`);

    return signatureId;
  },
);

export const signaturesAdapter = createEntityAdapter<Signature, EntityId>({
  selectId: (signature: Signature) => signature.id,
});

const upsertSignatureReducer = (
  state: EntityState<Signature, EntityId>, // Add the missing type argument for EntityState
  action: PayloadAction<Signature>,
) => {
  signaturesAdapter.upsertOne(state, action.payload);
};

const removeSignatureReducer = (
  state: EntityState<Signature, EntityId>, // Add the missing type argument for EntityState
  action: PayloadAction<EntityId>,
) => {
  signaturesAdapter.removeOne(state, action.payload);
};

const removeManySignaturesReducer = (
  state: EntityState<Signature, EntityId>,
  action: PayloadAction<EntityId[]>,
) => {
  signaturesAdapter.removeMany(state, action.payload);
};

const signatureSlice = createSlice({
  name: 'signature',
  initialState: signaturesAdapter.getInitialState(),
  reducers: {
    upsertSignature: upsertSignatureReducer,
    removeSignature: removeSignatureReducer,
    removeManySignature: removeManySignaturesReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSignature.fulfilled, signaturesAdapter.setAll)
      .addCase(fetchSignatureById.fulfilled, (state, { payload }) =>
        signaturesAdapter.setAll(state, [payload]),
      )
      .addCase(updateSignature.fulfilled, upsertSignatureReducer)
      .addCase(deleteSignature.fulfilled, removeSignatureReducer)
      .addCase(addNewSignature.fulfilled, (state, action) => {
        const { signature } = action.payload;

        // Ajoute ou met à jour la signature dans l'état
        signaturesAdapter.upsertOne(state, signature);
      });
  },
});

export const { upsertSignature, removeSignature, removeManySignature } =
  signatureSlice.actions;

// Exporter le reducer pour l'inclure dans le store
export default signatureSlice.reducer;
