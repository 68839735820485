import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  SideBarHandleContext,
  UserEditionHandleContext,
} from 'src/hooks/context';
import { ButtonBase, Drawer } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import CloseIcon from '@mui/icons-material/Close';
import { createManagedUser, updateManagedUser } from 'src/slices/auth';
import UserEditionBody from 'src/components/teams/userEditionBody';
import UserEditionFooter from 'src/components/teams/userEditionFooter';

const useStyles = createUseStyles({
  sideBar: {
    width: '100%',
    flexShrink: 0,
    zIndex: 1800,
    '& .MuiDrawer-paper': {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      overflowY: 'hidden',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      height: '100%',
    },
  },

  sideBarContainer: {
    height: '100%',
    overflow: 'scroll',
  },
  sideBarFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#EDF0FF',
    height: '100px',
    width: '100%',
    justifyContent: 'center',
    position: 'sticky',
    bottom: '0px',
  },
  sideBarBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '70px',
    position: 'relative',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#F7F8FD',
      borderRadius: '60px',
    },
  },

  closeButton: {
    position: 'absolute',
    top: '80px',
    right: '20px',
    borderRadius: '50%',
    color: '#414354',
    zIndex: '1',
  },
});

const UserEdition: React.FC = () => {
  const dispatch = useAppDispatch();
  const sidebarContext = useContext(SideBarHandleContext);
  const classes = useStyles();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const userEditionContext = useContext(UserEditionHandleContext);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phoneNumber: yup.string(),
    profilePictureUrl: yup.string(),
    company: yup.string(),
    companyLogo: yup.string().url(),
    position: yup.string().optional(),
    email: yup.string().optional().required(),
    website: yup.string().optional(),
    facebook: yup.string(),
    linkedin: yup.string().optional(),
    twitter: yup.string().optional(),
    address: yup.string().optional(),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    profilePictureUrl: '',
    company: user?.company ?? '',
    companyLogo: user?.companyLogo ?? '',
    position: '',
    email: '',
    website: '',
    facebook: '',
    linkedin: '',
    twitter: '',
    address: '',
  };

  const formikOnSubmit = async (values: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePictureUrl: string;
    company: string;
    companyLogo: string;
    position: string;
    email: string;
    website: string;
    facebook: string;
    linkedin: string;
    twitter: string;
    address: string;
  }) => {
    const basicValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      profilePictureUrl: values.profilePictureUrl,
      company: values.company,
      companyLogo: values.companyLogo,
      position: values.position,
      email: values.email,
      website: values.website,
      facebook: values.facebook,
      twitter: values.twitter,
      linkedin: values.linkedin,
      address: values.address,
    };

    if (userEditionContext?.managedUser) {
      dispatch(
        updateManagedUser({
          userId: user?._id,
          managedUserId: userEditionContext?.managedUser.user._id,
          firstName: basicValues.firstName,
          lastName: basicValues.lastName,
          phoneNumber: basicValues.phoneNumber,
          profilePictureUrl: basicValues.profilePictureUrl,
          company: basicValues.company,
          companyLogo: basicValues.companyLogo,
          position: basicValues.position,
          email: basicValues.email,
          website: basicValues.website,
          facebook: basicValues.facebook,
          twitter: basicValues.twitter,
          linkedin: basicValues.linkedin,
          associatedTag: userEditionContext.associatedTag?._id,
          address: basicValues.address,
        }),
      );
    } else {
      dispatch(
        createManagedUser({
          firstName: basicValues.firstName,
          lastName: basicValues.lastName,
          phoneNumber: basicValues.phoneNumber,
          profilePictureUrl: basicValues.profilePictureUrl,
          company: basicValues.company,
          companyLogo: basicValues.companyLogo,
          position: basicValues.position,
          email: basicValues.email,
          website: basicValues.website,
          facebook: basicValues.facebook,
          twitter: basicValues.twitter,
          linkedin: basicValues.linkedin,
          associatedTag: userEditionContext?.associatedTag?._id,
          address: basicValues.address,
        }),
      );
    }
    closeEditionSidebar();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: formikOnSubmit,
  });

  const resetForm = () => {
    formik.resetForm();
    formik.setValues({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      profilePictureUrl: '',
      company: user?.company ?? '',
      companyLogo: user?.companyLogo ?? '',
      position: '',
      email: '',
      website: '',
      facebook: '',
      linkedin: '',
      twitter: '',
      address: '',
    });
  };

  useEffect(() => {
    if (userEditionContext?.managedUser) {
      return formik.resetForm({
        values: {
          firstName: userEditionContext?.managedUser.user.firstName ?? '',
          lastName: userEditionContext?.managedUser.user.lastName ?? '',
          phoneNumber: userEditionContext?.managedUser.user.phoneNumber ?? '',
          profilePictureUrl:
            userEditionContext?.managedUser.user.profilePictureUrl ?? '',
          company:
            userEditionContext?.managedUser.user.company ?? user?.company ?? '',
          companyLogo:
            userEditionContext?.managedUser.user.companyLogo ??
            user?.companyLogo ??
            '',
          position: userEditionContext?.managedUser.user.position ?? '',
          email: userEditionContext?.managedUser.user.email ?? '',
          website: userEditionContext?.managedUser.user.website ?? '',
          facebook: userEditionContext?.managedUser.user.facebook ?? '',
          linkedin: userEditionContext?.managedUser.user.linkedin ?? '',
          twitter: userEditionContext?.managedUser.user.twitter ?? '',
          address: userEditionContext?.managedUser.user.address ?? '',
        },
      });
    }
  }, [userEditionContext?.managedUser]);

  const closeEditionSidebar = () => {
    resetForm();
    sidebarContext?.setOpenUserEdition(false);
    userEditionContext?.setIsNewUser(false);
    userEditionContext?.setAssociatedTag(undefined);
    userEditionContext?.setManagedUser(undefined);
  };

  return (
    <>
      <Drawer
        className={classes.sideBar}
        variant="temporary"
        anchor="right"
        open={sidebarContext?.isUserEditionOpened}
        onClose={() => closeEditionSidebar()}
      >
        <ButtonBase
          className={classes.closeButton}
          onClick={() => {
            sidebarContext?.setOpenUserEdition(false);
          }}
        >
          <CloseIcon />
        </ButtonBase>
        <div className={classes.sideBarBodyContainer}>
          <div className={`${classes.sideBarContainer}`}>
            <UserEditionBody formik={formik} />
          </div>
          {!(formik.isValid && formik.dirty) || formik.isSubmitting ? (
            <></>
          ) : (
            <div className={`${classes.sideBarFooter}`}>
              <UserEditionFooter formik={formik} />
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default UserEdition;
