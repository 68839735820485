import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';

export interface AddSignatureToGmailDto {
  signature?: string;
  userId?: string;
}

interface AuthUrlResponse {
  url: string;
}

type AddSignatureResponse = AuthUrlResponse | boolean;

export const addSignatureToGmailProcess = createAsyncThunk<
  AddSignatureResponse, // Ce que l'action retourne
  AddSignatureToGmailDto // Ce que l'action prend en paramètres
>(
  'gmail/addSignatureToGmailProcess',
  async (
    addSignatureToGmailDto: AddSignatureToGmailDto,
    { rejectWithValue },
  ) => {
    try {
      // Étape 2 : Obtenir l'URL d'autorisation avec la signature encodée dans le state
      const authUrlResponse = await apiRequest<{ url: string }>(
        'POST',
        '/gmail/auth-url',
        undefined,
        { ...addSignatureToGmailDto },
      );

      return authUrlResponse; // Retourne l'URL
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "Erreur lors du processus d'ajout de signature",
      );
    }
  },
);

export const handleOAuthCallback = createAsyncThunk(
  'gmail/handleOAuthCallback',
  async (code: string, { rejectWithValue }) => {
    try {
      // Envoyer le code d'autorisation au backend pour échanger contre les tokens
      const response = await apiRequest<{ message: string }>(
        'GET',
        `/gmail/oauth2callback?code=${code}`,
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || 'Erreur lors du callback OAuth',
      );
    }
  },
);
