import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { theme } from 'src/constants/theme';
import { Tag as TagModel, fetchAllTags } from 'src/slices/tag';
import { tagsSelector } from 'src/store';
import TagList from 'src/containers/team/tagList';
import { findAllManagedUsers } from 'src/slices/auth';

const useStyles = createUseStyles({
  teamsPage: {
    paddingTop: '16px',
    paddingLeft: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
  },
  teamsPageHeaderActions: {
    marginBottom: '15px',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  teamsPageHeaderActionsSearch: {},
  teamsPageHeaderActionsNew: {
    marginLeft: '30px',
  },
  teamsPageLoading: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  typographyTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '14px',
    color: '#060640',
  },
  buttonAddPage: {
    background: '#273EAC',
    borderRadius: '80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    color: 'white',
    position: 'absolute',
    right: '80px',
    top: '20px',
  },
  navigatorContainer: {
    display: 'flex',
    gap: '2px',
    paddingLeft: '30px',
  },
});

const Teams = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const { managedUsers } = useAppSelector(({ auth: { managedUsers } }) => ({
    managedUsers,
  }));

  const tags: TagModel[] = useAppSelector(tagsSelector.selectAll);

  useEffect(() => {
    if (user) {
      dispatch(findAllManagedUsers(user._id));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(fetchAllTags());
    }
  }, [user]);

  return (
    <>
      <div className={classes.teamsPage}>
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={classes.navigatorContainer}>
            <Typography className={classes.typographyTitle}>
              {t('common.teams')}
            </Typography>
            <Typography className={classes.typographyTitle}>{'>'}</Typography>
            <Typography className={classes.typographyTitle}>
              {t('common.tags')}
            </Typography>
            <Typography className={classes.typographyTitle}>
              {'(' + tags.length + ')'}
            </Typography>
          </div>
        </div>

        <TagList user={user} tags={tags} managedUsers={managedUsers} />
      </div>
    </>
  );
};

export default Teams;
