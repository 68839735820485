import React from 'react';

interface Props {
  user: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    profilePictureUrl?: string;
    companyLogo?: string;
    email: string;
    company?: string;
    position?: string;
    website?: string;
    facebook?: string;
    linkedin?: string;
    twitter?: string;
    address?: string;
  };
}

const SignatureModel4Email: React.FC<Props> = ({ user }) => {
  return (
    <table
      style={{
        width: '450px',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        tableLayout: 'fixed',
        height: '180px',
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              width: '70%',
              paddingRight: '16px',
            }}
          >
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#c77c53',
                    }}
                  >
                    {user.firstName} {user.lastName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: '16px',
                      color: '#999',
                      paddingTop: '4px',
                    }}
                  >
                    {user.position}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid #c77c53',
                      paddingTop: '8px',
                    }}
                  ></td>
                </tr>
                <tr>
                  <td style={{ fontSize: '14px', paddingTop: '8px' }}>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src="https://sign.kudeo.co/phone-icon.png"
                        alt="Phone"
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px',
                        }}
                      />
                      {user.phoneNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '8px' }}>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        href={user.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '10px' }}
                      >
                        <img
                          src="https://sign.kudeo.co/facebook-logo.png"
                          alt="Facebook"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                      <a
                        href={user.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: '10px' }}
                      >
                        <img
                          src="https://sign.kudeo.co/linkedin-logo.png"
                          alt="LinkedIn"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                      <a
                        href={user.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://sign.kudeo.co/x-logo.png"
                          alt="X"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>

          <td
            style={{
              width: '30%',
              textAlign: 'right',
            }}
          >
            <img
              src={user.companyLogo ?? ''}
              alt={user.company}
              style={{
                width: '100px',
                height: 'auto',
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SignatureModel4Email;
