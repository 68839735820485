import React from 'react';
import { Avatar, Stack, TextField } from '@mui/material';
import { Flex } from 'src/components/basic/Flex';
import { Upload } from 'src/components/basic/Upload';
import { theme } from 'src/constants/theme';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import {
  removeFile,
  uploadFileImage,
  UploadResultImage,
} from 'src/slices/file';
import { TextFieldWrapper } from 'src/components/basic/TextFieldWrapper';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    borderRadius: '8px',
    padding: '20px',
  },
  textFieldStyle: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CED3E8',
      },
      '& input': {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '15px',
        fontStyle: 'normal',
      },
      '&:hover fieldset': {
        borderColor: '#273EAC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#273EAC',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '15px',
      fontStyle: 'normal',
      '&.Mui-focused': {
        color: '#273EAC',
      },
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },
});

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  company: string;
  position: string;
  confirmPassword: string;
  profilePictureUrl: string;
  phoneNumber: string;
  companyLogo: string;
  website: string;
  facebook: string;
  linkedin: string;
  twitter: string;
  address: string;
}

interface Props {
  userSettingsFormik: FormikProps<FormValues>;
}

const UserConfiguration: React.FC<Props> = ({ userSettingsFormik }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const uploadOnChange = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          userSettingsFormik.setFieldValue('profilePictureUrl', payload.url);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadOnChangeCompanyLogo = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          userSettingsFormik.setFieldValue('companyLogo', payload.url);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const removeFileFunction = async (key: string, formikField: string) => {
    try {
      await dispatch(removeFile(key)).then(() => {
        userSettingsFormik.setFieldValue(formikField, '');
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className={classes.cardContainer}>
      <TextField
        margin="dense"
        id="firstName"
        className={classes.textFieldStyle}
        label={`${t('common.firstName')}`}
        type="text"
        fullWidth
        variant="outlined"
        name="firstName"
        value={userSettingsFormik.values.firstName}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.firstName &&
          Boolean(userSettingsFormik.errors.firstName)
        }
        helperText={
          userSettingsFormik.touched.firstName &&
          userSettingsFormik.errors.firstName
        }
      />
      <TextField
        margin="dense"
        id="lastName"
        className={classes.textFieldStyle}
        label={`${t('common.lastName')}`}
        type="text"
        fullWidth
        variant="outlined"
        name="lastName"
        value={userSettingsFormik.values.lastName}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.lastName &&
          Boolean(userSettingsFormik.errors.lastName)
        }
        helperText={
          userSettingsFormik.touched.lastName &&
          userSettingsFormik.errors.lastName
        }
      />
      <TextField
        margin="dense"
        id="phoneNumber"
        className={classes.textFieldStyle}
        label={`${t('common.phoneNumber')}`}
        type="phoneNumber"
        fullWidth
        variant="outlined"
        name="phoneNumber"
        value={userSettingsFormik.values.phoneNumber}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.phoneNumber &&
          Boolean(userSettingsFormik.errors.phoneNumber)
        }
        helperText={
          userSettingsFormik.touched.phoneNumber &&
          userSettingsFormik.errors.phoneNumber
        }
      />

      <TextField
        margin="dense"
        id="address"
        className={classes.textFieldStyle}
        label={`${t('common.address')}`}
        type="text"
        fullWidth
        variant="outlined"
        name="address"
        value={userSettingsFormik.values.address}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.address &&
          Boolean(userSettingsFormik.errors.address)
        }
        helperText={
          userSettingsFormik.touched.address &&
          userSettingsFormik.errors.address
        }
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
        spacing={2}
      >
        <TextFieldWrapper
          id="company"
          label={`${t('common.company')}`}
          type="text"
          className={classes.textFieldStyle}
          fullWidth
          variant="outlined"
          name="company"
          value={userSettingsFormik.values.company}
          onChange={userSettingsFormik.handleChange}
          onBlur={userSettingsFormik.handleBlur}
          error={
            userSettingsFormik.touched.company &&
            Boolean(userSettingsFormik.errors.company)
          }
          helperText={
            userSettingsFormik.touched.company &&
            userSettingsFormik.errors.company
          }
        />

        <TextFieldWrapper
          id="position"
          label={`${t('common.position')}`}
          type="text"
          fullWidth
          variant="outlined"
          className={classes.textFieldStyle}
          name="position"
          value={userSettingsFormik.values.position}
          onChange={userSettingsFormik.handleChange}
          onBlur={userSettingsFormik.handleBlur}
          error={
            userSettingsFormik.touched.position &&
            Boolean(userSettingsFormik.errors.position)
          }
          helperText={
            userSettingsFormik.touched.position &&
            userSettingsFormik.errors.position
          }
        />
      </Stack>

      <TextField
        margin="dense"
        id="website"
        className={classes.textFieldStyle}
        label={`${t('common.website')}`}
        type="text"
        fullWidth
        variant="outlined"
        name="website"
        value={userSettingsFormik.values.website}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.website &&
          Boolean(userSettingsFormik.errors.website)
        }
        helperText={
          userSettingsFormik.touched.website &&
          userSettingsFormik.errors.website
        }
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
        spacing={2}
      >
        <TextFieldWrapper
          id="facebook"
          label={`${t('common.facebook')}`}
          type="text"
          className={classes.textFieldStyle}
          fullWidth
          variant="outlined"
          name="facebook"
          value={userSettingsFormik.values.facebook}
          onChange={userSettingsFormik.handleChange}
          onBlur={userSettingsFormik.handleBlur}
          error={
            userSettingsFormik.touched.facebook &&
            Boolean(userSettingsFormik.errors.facebook)
          }
          helperText={
            userSettingsFormik.touched.facebook &&
            userSettingsFormik.errors.facebook
          }
        />

        <TextFieldWrapper
          id="linkedin"
          label={`${t('common.linkedin')}`}
          type="text"
          fullWidth
          variant="outlined"
          className={classes.textFieldStyle}
          name="linkedin"
          value={userSettingsFormik.values.linkedin}
          onChange={userSettingsFormik.handleChange}
          onBlur={userSettingsFormik.handleBlur}
          error={
            userSettingsFormik.touched.linkedin &&
            Boolean(userSettingsFormik.errors.linkedin)
          }
          helperText={
            userSettingsFormik.touched.linkedin &&
            userSettingsFormik.errors.linkedin
          }
        />

        <TextFieldWrapper
          id="twitter"
          label={`${t('common.twitter')}`}
          type="text"
          fullWidth
          variant="outlined"
          className={classes.textFieldStyle}
          name="twitter"
          value={userSettingsFormik.values.twitter}
          onChange={userSettingsFormik.handleChange}
          onBlur={userSettingsFormik.handleBlur}
          error={
            userSettingsFormik.touched.twitter &&
            Boolean(userSettingsFormik.errors.twitter)
          }
          helperText={
            userSettingsFormik.touched.twitter &&
            userSettingsFormik.errors.twitter
          }
        />
      </Stack>

      <TextField
        margin="dense"
        id="password"
        label={`${t('common.password')}`}
        type="password"
        className={classes.textFieldStyle}
        fullWidth
        variant="outlined"
        name="password"
        value={userSettingsFormik.values.password}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          userSettingsFormik.touched.password &&
          Boolean(userSettingsFormik.errors.password)
        }
        helperText={
          userSettingsFormik.touched.password &&
          userSettingsFormik.errors.password
        }
      />

      <TextField
        margin="dense"
        id="confirmPassword"
        label={`${t('forms.confirmPassword')}`}
        type="password"
        className={classes.textFieldStyle}
        fullWidth
        variant="outlined"
        name="confirmPassword"
        value={userSettingsFormik.values.confirmPassword}
        onChange={userSettingsFormik.handleChange}
        onBlur={userSettingsFormik.handleBlur}
        error={
          (userSettingsFormik.touched.confirmPassword &&
            Boolean(userSettingsFormik.errors.confirmPassword)) ||
          (userSettingsFormik.values.password !== '' &&
            userSettingsFormik.values.password !==
              userSettingsFormik.values.confirmPassword)
        }
        helperText={
          userSettingsFormik.touched.confirmPassword &&
          userSettingsFormik.errors.confirmPassword
        }
      />

      <Flex style={{ marginTop: theme.spacing(3), alignItems: 'center' }}>
        <div
          style={{
            marginRight: theme.spacing(2),
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          {t('common.profilePicture')} :
        </div>
        <Avatar
          style={{ marginRight: theme.spacing(2) }}
          alt="avatar"
          src={userSettingsFormik.values.profilePictureUrl}
          aria-label="avatar"
        />
        <Upload
          onChange={(e) => {
            if (userSettingsFormik.values.profilePictureUrl === '') {
              uploadOnChange(e);
            }
          }}
          onClick={() => {
            if (userSettingsFormik.values.profilePictureUrl !== '') {
              removeFileFunction(
                userSettingsFormik.values.profilePictureUrl,
                'profilePictureUrl',
              );
            }
          }}
          type={
            userSettingsFormik.values.profilePictureUrl !== ''
              ? 'button'
              : undefined
          }
          id="profilePictureUpload"
          file="image"
        />
      </Flex>

      <Flex style={{ marginTop: theme.spacing(3), alignItems: 'center' }}>
        <div
          style={{
            marginRight: theme.spacing(2),
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          {t('common.logoCompany')} :
        </div>
        <Avatar
          style={{ marginRight: theme.spacing(2) }}
          alt="avatar"
          src={userSettingsFormik.values.companyLogo}
          aria-label="avatar"
        />
        <Upload
          onChange={(e) => {
            if (userSettingsFormik.values.companyLogo === '') {
              uploadOnChangeCompanyLogo(e);
            }
          }}
          onClick={() => {
            if (userSettingsFormik.values.companyLogo !== '') {
              removeFileFunction(
                userSettingsFormik.values.companyLogo,
                'companyLogo',
              );
            }
          }}
          type={
            userSettingsFormik.values.companyLogo !== '' ? 'button' : undefined
          }
          id="companyLogoUpload"
          file="image"
        />
      </Flex>
    </div>
  );
};

export default UserConfiguration;
