import React from 'react';
import { ButtonBase } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchCurrentUser, updateUser, UpdateUserDto } from 'src/slices/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { handleNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import UserConfiguration from 'src/containers/settings/UserConfiguration';

const useStyles = createUseStyles({
  settingsPage: {
    paddingTop: '30px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  settingsContainer: {
    maxHeight: 'calc(100% - 70px)',
    paddingLeft: '20%',
    paddingRight: '20%',
    paddingBottom: '10px',
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  buttonWrapper: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    flexDirection: 'row',
    background: 'white',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    // boxShadow: '0 -1px 30px 0 rgba(50, 50, 93, 0.05)',
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px -1px 1px 0px inset, rgba(50, 50, 93, 0.1) 0px -50px 100px -20px, rgba(0, 0, 0, 0.2) 0px -30px 60px -30px',
  },

  tooltipContainer: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
  },

  previousButton: {
    fontFamily: 'Montserrat',
    fontSize: '10px',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  button: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    flex: 1,
  },
});

const Settings = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  if (!user) {
    return <></>;
  }

  const userSettingsValidationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    company: yup.string(),
    password: yup.string().min(
      10,
      t('forms.rules.min2', {
        value: 10,
      }),
    ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('forms.errorConfirmPasswordNotMatch')),
    profilePictureUrl: yup.string(),
    companyLogo: yup.string(),
    phoneNumber: yup.string(),
    website: yup.string(),
    facebook: yup.string(),
    linkedin: yup.string(),
    twitter: yup.string(),
    address: yup.string(),
  });

  const userSettingsInitialValues = {
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    company: user.company ?? '',
    position: user.position ?? '',
    password: '',
    confirmPassword: '',
    phoneNumber: user.phoneNumber ?? '',
    profilePictureUrl: user.profilePictureUrl ?? '',
    companyLogo: user.companyLogo ?? '',
    website: user.website ?? '',
    facebook: user.facebook ?? '',
    linkedin: user.linkedin ?? '',
    twitter: user.twitter ?? '',
    address: user.address ?? '',
  };

  const userSettingsFormikOnSubmit = async (values: {
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    companyLogo: string;
    profilePictureUrl: string;
    website: string;
    facebook: string;
    linkedin: string;
    twitter: string;
    address: string;
  }) => {
    if (userSettingsFormik.dirty) {
      const updatedValues: UpdateUserDto = {
        firstName:
          user.firstName !== values.firstName ? values.firstName : undefined,
        lastName:
          user.lastName !== values.lastName ? values.lastName : undefined,
        company: user.company !== values.company ? values.company : undefined,
        position:
          user.position !== values.position ? values.position : undefined,
        authProviders:
          values.password !== '' && values.password === values.confirmPassword
            ? { local: values.password }
            : undefined,
        phoneNumber:
          user.phoneNumber !== values.phoneNumber
            ? values.phoneNumber
            : undefined,
        profilePictureUrl:
          user.profilePictureUrl !== values.profilePictureUrl
            ? values.profilePictureUrl
            : undefined,
        companyLogo:
          user.companyLogo !== values.companyLogo
            ? values.companyLogo
            : undefined,
        website: user.website !== values.website ? values.website : undefined,
        facebook:
          user.facebook !== values.facebook ? values.facebook : undefined,
        linkedin:
          user.linkedin !== values.linkedin ? values.linkedin : undefined,
        twitter: user.twitter !== values.twitter ? values.twitter : undefined,
        address: user.address !== values.address ? values.address : undefined,
      };

      const isChangingValue = Object.keys(updatedValues).some(
        (k) => (updatedValues as any)[k] !== undefined,
      );

      isChangingValue &&
        (await handleNotification(
          dispatch(updateUser(updatedValues)),
          t('pages.Settings.successProfileUpdate'),
          t('pages.Settings.errorProfileUpdate'),
          enqueueSnackbar,
          closeSnackbar,
        ));

      return isChangingValue;
    }
  };

  const userSettingsFormik = useFormik({
    initialValues: userSettingsInitialValues,
    validationSchema: userSettingsValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: userSettingsFormikOnSubmit,
  });

  const saveSettings = async (event: any) => {
    const userSettingsChanges = await userSettingsFormik.submitForm();

    if (userSettingsChanges) {
      dispatch(fetchCurrentUser());
    }
    event.preventDefault();
  };

  return user === null ? (
    <></>
  ) : (
    <div className={classes.settingsPage}>
      <div className={classes.settingsContainer}>
        <UserConfiguration
          key="profile"
          userSettingsFormik={userSettingsFormik}
        />
      </div>
      <div className={`${classes.buttonWrapper}`}>
        <ButtonBase
          onClick={() => {
            window.location.reload();
          }}
          className={`${classes.button}`}
          style={{
            background: 'white',
            color: '#273EAC',
            border: '1px solid #273EAC',
          }}
        >
          {t('common.cancel')}
        </ButtonBase>

        <ButtonBase onClick={saveSettings} className={classes.button}>
          {t('common.save')}
        </ButtonBase>
      </div>
    </div>
  );
};

export default Settings;
