import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch } from 'src/hooks/store';
import {
  SideBarHandleContext,
  SignatureEditionHandleContext,
} from 'src/hooks/context';
import { ButtonBase, Drawer } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import CloseIcon from '@mui/icons-material/Close';
import { addNewSignature, updateSignature } from 'src/slices/signature';
import SignatureEditionBody from 'src/components/signature/signatureEditionBody';
import SignatureEditionFooter from 'src/components/signature/signatureEditionFooter';

const useStyles = createUseStyles({
  sideBar: {
    width: '100%',
    flexShrink: 0,
    zIndex: 1800,
    '& .MuiDrawer-paper': {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
      overflowY: 'hidden',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
      height: '100%',
    },
  },

  sideBarContainer: {
    height: '100%',
    overflow: 'scroll',
  },
  sideBarFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#EDF0FF',
    height: '100px',
    width: '100%',
    justifyContent: 'center',
    position: 'sticky',
    bottom: '0px',
  },
  sideBarBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '70px',
    position: 'relative',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#F7F8FD',
      borderRadius: '60px',
    },
  },

  closeButton: {
    position: 'absolute',
    top: '80px',
    right: '20px',
    borderRadius: '50%',
    color: '#414354',
    zIndex: '1',
  },
});

const SignatureEdition: React.FC = () => {
  const dispatch = useAppDispatch();
  const sidebarContext = useContext(SideBarHandleContext);
  const classes = useStyles();

  const signatureEditionContext = useContext(SignatureEditionHandleContext);

  const validationSchema = yup.object().shape({
    body: yup.string().required(),
    tag: yup.string().required(),
  });

  const initialValues = {
    body: '',
    tag: '',
  };

  const formikOnSubmit = async (values: { body: string; tag: string }) => {
    const basicValues = {
      body: values.body,
      tag: values.tag,
    };

    if (signatureEditionContext?.signature) {
      dispatch(
        updateSignature({
          id: signatureEditionContext.signature.id,
          body: basicValues.body,
        }),
      );
    } else {
      dispatch(
        addNewSignature({
          body: basicValues.body,
          model: 1,
          tagId: basicValues.tag,
        }),
      );
    }
    closeEditionSidebar();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: formikOnSubmit,
  });

  const resetForm = () => {
    formik.resetForm();
    formik.setValues({
      body: '',
      tag: '',
    });
  };

  useEffect(() => {
    if (signatureEditionContext?.signature) {
      return formik.resetForm({
        values: {
          body: signatureEditionContext?.signature.body ?? '',
          tag: signatureEditionContext?.tag?._id ?? '',
        },
      });
    }
  }, [signatureEditionContext?.signature]);

  const closeEditionSidebar = () => {
    resetForm();
    sidebarContext?.setOpenUserEdition(false);
    signatureEditionContext?.setIsNewSignature(false);
    signatureEditionContext?.setSignature(undefined);
  };

  return (
    <>
      <Drawer
        className={classes.sideBar}
        variant="temporary"
        anchor="right"
        open={sidebarContext?.isSignatureEditionOpened}
        onClose={() => closeEditionSidebar()}
      >
        <ButtonBase
          className={classes.closeButton}
          onClick={() => {
            sidebarContext?.setOpenUserEdition(false);
          }}
        >
          <CloseIcon />
        </ButtonBase>
        <div className={classes.sideBarBodyContainer}>
          <div className={`${classes.sideBarContainer}`}>
            <SignatureEditionBody formik={formik} />
          </div>
          {!(formik.isValid && formik.dirty) || formik.isSubmitting ? (
            <></>
          ) : (
            <div className={`${classes.sideBarFooter}`}>
              <SignatureEditionFooter formik={formik} />
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default SignatureEdition;
