import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedLayout } from 'src/containers/LoggedLayout';
import SideBarProvider from 'src/hooks/SideBarProvider';
import Settings from 'src/pages/Settings';
import { useAppSelector } from 'src/hooks/store';
import Admin from 'src/pages/Admin';
import { Roles } from 'src/slices/auth';
import Teams from 'src/pages/Teams';
import Signature from 'src/pages/Signature';
import Tag from 'src/pages/Tag';
import SuccessPage from 'src/pages/SuccessPage';

export const LoggedSwitch: React.FC = () => {
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  return user?.isActiveAccount ? (
    <>
      <SideBarProvider>
        <LoggedLayout>
          {user?.role === Roles.MANAGER || user?.role === Roles.ADMIN ? (
            <Routes>
              <Route path="/signature" element={<Signature />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/dashboard" element={<Settings />} />
              <Route path="/tags" element={<Tag />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/success" element={<SuccessPage />} />
              {user?.isAdmin ? (
                <Route path="/admin" element={<Admin />} />
              ) : null}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/signature" element={<Signature />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="*" element={<Navigate to="/settings" replace />} />
            </Routes>
          )}
        </LoggedLayout>
      </SideBarProvider>
    </>
  ) : (
    <></>
  );
};
