import React from 'react';
import { ManagedUser } from 'src/slices/auth';
import { Signature } from 'src/slices/signature';
import { Tag } from 'src/slices/tag';

/** Sidebars **/

interface SideBarProps {
  isUserEditionOpened: boolean;
  setOpenUserEdition: React.Dispatch<React.SetStateAction<boolean>>;

  isSignatureEditionOpened: boolean;
  setOpenSignatureEdition: React.Dispatch<React.SetStateAction<boolean>>;

  isSignatureSelectorOpened: boolean;
  setOpenSignatureSelector: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarHandleContext = React.createContext<
  SideBarProps | undefined
>(undefined);

////////// User edition

interface UserEditionSideBarProps {
  isNewUser: boolean;
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
  managedUser: ManagedUser | undefined;
  setManagedUser: React.Dispatch<React.SetStateAction<ManagedUser | undefined>>;
  associatedTag: Tag | undefined;
  setAssociatedTag: React.Dispatch<React.SetStateAction<Tag | undefined>>;
}

export const UserEditionHandleContext = React.createContext<
  UserEditionSideBarProps | undefined
>(undefined);

////////// Signature edition

interface SignatureEditionSideBarProps {
  isNewSignature: boolean;
  setIsNewSignature: React.Dispatch<React.SetStateAction<boolean>>;

  signature: Signature | undefined;
  setSignature: React.Dispatch<React.SetStateAction<Signature | undefined>>;

  tag: Tag | undefined;
  setTag: React.Dispatch<React.SetStateAction<Tag | undefined>>;
}

export const SignatureEditionHandleContext = React.createContext<
  SignatureEditionSideBarProps | undefined
>(undefined);

////////////////// Signature selector

interface SignatureSelectorSideBarProps {
  signature: Signature | undefined;
  setSignature: React.Dispatch<React.SetStateAction<Signature | undefined>>;

  tag: Tag | undefined;
  setTag: React.Dispatch<React.SetStateAction<Tag | undefined>>;
}

export const SignatureSelectorHandleContext = React.createContext<
  SignatureSelectorSideBarProps | undefined
>(undefined);

/** Actions **/
