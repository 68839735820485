import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import PreviewSignature from 'src/components/signature/previewSignature';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Tag as TagModel, fetchAllTags } from 'src/slices/tag';
import { tagsSelector } from 'src/store';

const useStyles = createUseStyles({
  signaturePage: {
    padding: '30px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
});

const Signature = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const tags: TagModel[] = useAppSelector(tagsSelector.selectAll);

  useEffect(() => {
    if (user) {
      dispatch(fetchAllTags());
    }
  }, [user]);

  return user === null ? (
    <></>
  ) : (
    <div className={classes.signaturePage}>
      {tags.map((tag, index) => (
        <React.Fragment key={index}>
          <PreviewSignature user={user} tag={tag} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Signature;
