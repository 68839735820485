import { ButtonBase } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = createUseStyles({
  signatureEditionActions: {
    background: '#273EAC',
    borderRadius: '8px',
    width: '200px',
    height: '60px',
    gap: '10px',
    color: 'white',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },

  buttonContainer: {
    display: 'flex',
    gap: '30px',
  },

  iconSave: {
    transform: 'rotateY(180deg)',
  },
});

interface FormValues {
  body: string;
  tag: string;
}

interface Props {
  formik: FormikProps<FormValues>;
}

const SignatureEditionFooter: React.FC<Props> = ({ formik }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const saveUser = async () => {
    await formik.submitForm();
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <ButtonBase
          className={classes.signatureEditionActions}
          onClick={() => {
            saveUser();
          }}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
          style={
            !(formik.isValid && formik.dirty) || formik.isSubmitting
              ? { color: 'grey', background: 'none', border: '1px solid grey' }
              : {}
          }
        >
          <SaveIcon className={classes.iconSave} />
          {t('common.save')}
        </ButtonBase>
      </div>
    </>
  );
};

export default SignatureEditionFooter;
