import React from 'react';

interface Props {
  user: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    profilePictureUrl?: string;
    companyLogo?: string;
    email: string;
    company?: string;
    position?: string;
    website?: string;
    facebook?: string;
    linkedin?: string;
    twitter?: string;
    address?: string;
  };
}

const SignatureModele1Email: React.FC<Props> = ({ user }) => {
  return (
    <table
      width="100%"
      style={{
        width: '450px',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        height: '180px',
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              width: '120px',
              textAlign: 'center',
            }}
          >
            <img
              src={user?.profilePictureUrl ?? ''}
              alt={user?.firstName}
              style={{
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                marginBottom: '15px',
              }}
            />
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <a
                href={user.facebook}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px', display: 'inline-block' }} // Ajoute un espace entre les icônes
              >
                <img
                  src="https://sign.kudeo.co/facebook-logo.png"
                  alt="Facebook"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
              <a
                href={user.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '10px', display: 'inline-block' }} // Ajoute un espace entre les icônes
              >
                <img
                  src="https://sign.kudeo.co/linkedin-logo.png"
                  alt="LinkedIn"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
              <a
                href={user.twitter}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'inline-block' }}
              >
                <img
                  src="https://sign.kudeo.co/x-logo.png"
                  alt="Twitter"
                  style={{ width: '20px', height: '20px' }}
                />
              </a>
            </div>
          </td>
          <td style={{ paddingLeft: '16px', textAlign: 'left' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#f57c00',
                    }}
                  >
                    {user.firstName} {user.lastName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: '14px',
                      color: '#999',
                      paddingTop: '4px',
                    }}
                  >
                    {user.position}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: '1px solid #f57c00',
                      paddingTop: '8px',
                    }}
                  ></td>
                </tr>
                <tr>
                  <td style={{ fontSize: '14px', paddingTop: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src="https://sign.kudeo.co/location-modele-1.png"
                        alt="Location"
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px',
                        }}
                      />
                      {user.address}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '4px',
                      }}
                    >
                      <img
                        src="https://sign.kudeo.co/phone-modele-1.png"
                        alt="Phone"
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px',
                        }}
                      />
                      {user.phoneNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '8px' }}>
                    <a
                      href={user.website}
                      target="_blank"
                      style={{
                        display: 'inline-block',
                        backgroundColor: '#f57c00',
                        color: '#fff',
                        padding: '6px 12px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        textDecoration: 'none',
                      }}
                      rel="noopener noreferrer"
                    >
                      {user.company}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SignatureModele1Email;
