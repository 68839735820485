import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Flex } from 'src/components/basic/Flex';
import ProfileCard from 'src/components/basic/ProfileCard';
import { theme } from 'src/constants/theme';

import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  activateAccount,
  addAdminAccount,
  adminGetAllUsers,
  deleteAccount,
  updateUserEmailAndPassword,
  User,
  undoAdminAccount,
} from 'src/slices/auth';

const useStyles = createUseStyles({
  adminContainer: {
    height: '833px',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  usersPageDataGrid: {
    cursor: 'default',
    boxShadow:
      'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, \
     &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
      {
        outline: 'none',
      },
  },
  userPageActions: {
    marginTop: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },

  userSelectorContainer: {
    display: 'flex',
    gap: '22px',
    flexDirection: 'column',
  },
});

const Admin = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { users } = useAppSelector(({ auth: { users } }) => ({
    users,
  }));
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState<string>('');
  const [confirmChangeSubmit, setConfirmChangeSubmit] = useState<string>('');
  const [confirmChangeSubmitType, setConfirmChangeSubmitType] =
    useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [openChangeEmailAndPassword, setOpenChangeEmailAndPassword] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(adminGetAllUsers());
  }, []);

  useEffect(() => {
    if (confirmChangeSubmit != '') {
      const tempUser = users.find((user) => {
        return user._id === confirmChangeSubmit;
      })?.email;
      if (tempUser) {
        setEmail(tempUser);
      }
    }
  }, [confirmChangeSubmitType]);

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: t('common.firstName'), flex: 1 },
    { field: 'lastName', headerName: t('common.lastName'), flex: 1 },
    { field: 'email', headerName: 'E-mail', flex: 1 },
    { field: 'company', headerName: t('pages.Admin.company'), flex: 1 },
    {
      field: 'createdAt',
      headerName: t('pages.Admin.date'),
      flex: 1,
      renderCell: (cellValues) => (
        <>
          <div>
            {new Date(cellValues.row.createdAt).toLocaleDateString()} -{' '}
            {new Date(cellValues.row.createdAt).toLocaleTimeString()}
          </div>
        </>
      ),
    },
    {
      field: 'lastConnection',
      headerName: t('pages.Admin.lastConnection'),
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            {new Date(cellValues.row.lastConnection).toLocaleDateString()} -{' '}
            {new Date(cellValues.row.lastConnection).toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      field: 'numberOfConnections',
      headerName: t('pages.Admin.numberOfConnections'),
      flex: 1,
    },
  ];

  const adminDeleteAccount = (userId: string) => {
    setDeleteUserDialog(userId);
    setOpenDeleteDialog(true);
  };

  const dialogAdminDeleteAccount = async () => {
    if (deleteUserDialog !== '') {
      await dispatch(deleteAccount(deleteUserDialog));
      setOpenDeleteDialog(false);
      setDeleteUserDialog('');
    }
  };

  const confirmChangePasswordAndEmail = async () => {
    if (confirmChangeSubmitType != '') {
      await dispatch(
        updateUserEmailAndPassword({
          userId: confirmChangeSubmitType,
          email: email,
          password: password,
        }),
      );
      setConfirmChangeSubmit('');
      setOpenChangeEmailAndPassword(false);
    }
  };

  const addAdmin = (userId: string) => {
    dispatch(addAdminAccount(userId));
  };

  const undoAdminAccountFunction = (userId: string) => {
    dispatch(undoAdminAccount(userId));
  };

  const adminActivateAccount = (userId: string) => {
    dispatch(activateAccount(userId));
  };

  const changeUserEmailAndPassword = async (userId: string) => {
    setConfirmChangeSubmitType(userId);
    setOpenChangeEmailAndPassword(true);
  };

  const [sortedUsers, setSortedUsers] = useState<User[]>([]);

  useEffect(() => {
    const temp = [...users];
    setSortedUsers(
      temp.sort((a: User, b: User) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : undefined;
        const dateB = b.createdAt ? new Date(b.createdAt) : undefined;
        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        } else if (a.createdAt) {
          return -1;
        } else {
          return 1;
        }
      }),
    );
  }, [users]);

  return (
    <div className={classes.adminContainer}>
      <DataGrid
        className={classes.usersPageDataGrid}
        loading={false}
        rows={sortedUsers.map((user) => ({
          id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          company: user?.company ?? '',
          email: user.email,
          createdAt: user.createdAt,
          lastConnection: user.stats?.lastConnection ?? user.createdAt,
          numberOfConnections: user.stats?.numberOfConnections ?? 0,
          isAdmin: user?.isAdmin ?? false,
        }))}
        columns={columns}
        checkboxSelection
        autoPageSize
        onRowSelectionModelChange={(gridUsers: any) =>
          setSelectedUsers(
            gridUsers.map((gridUser: any) => gridUser.toString()),
          )
        }
        sx={{
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-columnHeader': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
        }}
      />
      <Flex className={classes.userPageActions}>
        <Button
          variant="contained"
          onClick={() => {
            setDialogOpened(true);
          }}
          disabled={selectedUsers.length === 0}
        >
          {t('pages.Admin.showUsers')}
        </Button>
      </Flex>
      <Dialog
        open={isDialogOpened && selectedUsers.length !== 0}
        onClose={() => setDialogOpened(false)}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {users
              .filter((user: User) =>
                selectedUsers.find((selectedUser) => user._id === selectedUser),
              )
              .map((user) => {
                return (
                  <Grid item xs={12} key={user._id}>
                    <ProfileCard
                      user={user}
                      addAdmin={addAdmin}
                      undoAdminAccountFunction={undoAdminAccountFunction}
                      adminDeleteAccount={adminDeleteAccount}
                      activateAccount={adminActivateAccount}
                      changeUserEmailAndPassword={changeUserEmailAndPassword}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setDeleteUserDialog('');
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.confirmDeleteUserTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pages.Admin.confirmDeleteUser')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setDeleteUserDialog('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => dialogAdminDeleteAccount()} autoFocus>
            {t('common.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeEmailAndPassword}
        onClose={() => {
          setOpenChangeEmailAndPassword(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('pages.Admin.changeSubscription')}
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex' }}>
            <TextField
              defaultValue={email}
              placeholder={'example@example.com'}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
            <TextField
              defaultValue={password}
              placeholder={'***'}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              type="password"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangeEmailAndPassword(false);
              setConfirmChangeSubmit('');
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={() => confirmChangePasswordAndEmail()} autoFocus>
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Admin;
