import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { LostPassword } from '../pages/LostPassword';
import { ActivateAccount } from '../pages/ActivateAccount';
import SignUpManagedUser from 'src/pages/SignUpManagedUser';

export const GuestSwitch: React.FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<SignIn />} />
      <Route path={'/signIn'} element={<SignIn />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/lostPassword" element={<LostPassword />} />
      <Route path="/activateAccount" element={<ActivateAccount />} />
      <Route
        path="/add/:managedUserId/:managerUserId"
        element={<SignUpManagedUser />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
