import React from 'react';

const SuccessPage = () => {
  return (
    <div>
      <h1>Authentification réussie !</h1>
      <p>Ajout de votre signature en cours...</p>
    </div>
  );
};

export default SuccessPage;
