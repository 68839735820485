import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';

const useStyles = createUseStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    borderRadius: '8px',
    padding: '100px',
  },
});

interface FormValues {
  body: string;
  tag: string;
}

interface Props {
  formik: FormikProps<FormValues>;
}

const SignatureEditionBody: React.FC<Props> = ({ formik }) => {
  const classes = useStyles();

  return (
    <form className={classes.cardContainer} onSubmit={formik.handleSubmit}>
      test
    </form>
  );
};

export default SignatureEditionBody;
