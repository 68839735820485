import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './hooks/store';
import { fetchCurrentUser } from './slices/auth';
import { GuestSwitch } from './routes/GuestSwitch';
import { LoggedSwitch } from './routes/LoggedSwitch';
import { FullscreenSpin } from './components/basic/FullscreenSpin';
import './locales/i18n';
import ThemeConfig from './constants/theme';
import GlobalStyles from './constants/theme/globalStyles';
import { SnackbarProvider } from 'notistack';
import { Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Redirection } from './pages/Redirection';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  const dispatch = useAppDispatch();

  const { isLogged, isInitialLoading } = useAppSelector(
    ({ auth: { isLogged, isInitialLoading } }) => ({
      isLogged,
      isInitialLoading,
    }),
  );

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [window.innerWidth]);

  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <ThemeConfig>
      <GlobalStyles />
      <I18nextProvider i18n={i18next}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <SnackbarProvider
            ref={notistackRef}
            autoHideDuration={2000}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
            maxSnack={4}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            TransitionComponent={Collapse}
          >
            {isSmallScreen ? (
              <Redirection />
            ) : (
              <>
                {isInitialLoading ? (
                  <FullscreenSpin />
                ) : isLogged ? (
                  <LoggedSwitch />
                ) : (
                  <GuestSwitch />
                )}
              </>
            )}
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </I18nextProvider>
    </ThemeConfig>
  );
};

export default App;
