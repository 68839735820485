import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';

import {
  apiRequest,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../helpers/api';
import { Tag } from './tag';
import { Signature } from './signature';

export interface Stats {
  lastConnection: Date;
  numberOfConnections: number;
}

interface AuthProvider {
  local?: string;
  google?: string;
}

export interface ManagedUser {
  user: User;
  state: boolean;
}

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  email: string;
  company?: string;
  position?: string;
  locale: string;
  stats: Stats;
  address: string;
  isAdmin: boolean;
  isActiveAccount: boolean;
  website?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  role?: Roles;
  managedUser: ManagedUser[];
  tags: Tag[];
  signatures: Signature[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ManagedUser {
  user: User;
  tag: Tag;
  state: boolean;
}

export enum Roles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  USER = 'user',
}

export interface UpdateUserDto {
  firstName?: string;
  email?: string;
  lastName?: string;
  company?: string;
  position?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  locale?: string;
  stats?: Stats;
  authProviders?: AuthProvider;
  phoneNumber?: string;
  website?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  address?: string;
}

export interface UpdateUserEmailAndPasswordDto {
  userId?: string;
  email?: string;
  password?: string;
}

interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

interface AuthState {
  user: User | null;
  users: User[];
  isLogged: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
  error: string | null;
  isAdmin: boolean;
  managedUsers: ManagedUser[];
}

const initialState: AuthState = {
  isLogged: false,
  isLoading: false,
  isInitialLoading: true,
  user: null,
  users: [],
  error: null,
  isAdmin: false,
  managedUsers: [],
};

export interface UpdateManagedUserDto {
  userId?: string;
  managedUserId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  company?: string;
  position?: string;
  email?: string;
  website?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  associatedTag?: string;
  address?: string;
}

export interface ConfirmManagedUserDto {
  managerUserId?: string;
  managedUserId?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  company?: string;
  position?: string;
  email?: string;
  website?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  address?: string;
  authProviders?: AuthProvider;
}

export interface SendMailToUserDto {
  managerUser?: string;
  managedUser?: string;
}

export interface AddSignatureToGmailDto {}

const setAccessRefreshTokenAndGetUser = (
  accessToken: string,
  refreshToken?: string,
) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
  if (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }

  return apiRequest<User>('GET', '/auth/user');
};

/* Thunks */

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user');
    } catch (error) {
      return null;
    }
  },
);

export const fetchUserById = createAsyncThunk(
  'auth/fetchUserById',
  async (userId: string) => {
    const user = await apiRequest<User>('GET', `/user/${userId}`);
    return user;
  },
);

export const confirmManagedUser = createAsyncThunk(
  'auth/confirmManagedUser',
  async (confirmManagedUserDto: ConfirmManagedUserDto) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/confirmManagedUser`,
      undefined,
      { ...confirmManagedUserDto },
    );
    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const fetchCurrentUserStats = createAsyncThunk(
  'auth/fetchCurrentUserStats',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user/stats');
    } catch (error) {
      return null;
    }
  },
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async (updateUserDto: UpdateUserDto) => {
    const result = await apiRequest<User>('PUT', '/auth/user', undefined, {
      ...updateUserDto,
    });

    return result;
  },
);

export const updateUserEmailAndPassword = createAsyncThunk(
  'user/updateUserEmailAndPassword',
  async (updateUserDto: UpdateUserEmailAndPasswordDto) => {
    const result = await apiRequest<User>(
      'PUT',
      '/user/update/user',
      undefined,
      {
        ...updateUserDto,
      },
    );

    return result;
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: { email: string; password: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      '/auth/signIn',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendMailRedirection = createAsyncThunk(
  'auth/sendMailRedirection',
  async (payload: { email: string; locale: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/redirection',
      undefined,
      payload,
    );
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: {
    email: string;
    password: string;
    locale: string;
    roles: Roles;
  }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/signUp',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signInUpGoogle = createAsyncThunk(
  'auth/signInUpGoogle',
  async (payload: { googleAccessToken: string; locale: string; user: any }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/google',
      undefined,
      // eslint-disable-next-line camelcase
      {
        accessToken: payload.googleAccessToken,
        locale: payload.locale,
        user: payload.user,
      },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendLostPassword = createAsyncThunk(
  'auth/sendLostPassword',
  async (payload: { email: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/lostPassword',
      undefined,
      payload,
    );
  },
);

export const useActivationAccount = createAsyncThunk(
  'auth/useActivationAccount',
  async (payload: any) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/activateAccount/${encodeURIComponent(payload)}`,
      undefined,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const useLostPasswordToken = createAsyncThunk(
  'auth/useLostPasswordToken',
  async (payload: { token: string; password: string }) => {
    const { token, password } = payload;
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/lostPassword/${encodeURIComponent(token)}`,
      undefined,
      { password },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const findAllManagedUsers = createAsyncThunk(
  'auth/findAllManagedUsers',
  async (userId: string) => {
    const managedUsers = await apiRequest<ManagedUser[]>(
      'GET',
      `/user/managedUser/${userId}`,
      undefined,
    );

    return await managedUsers;
  },
);

export const updateManagedUser = createAsyncThunk(
  'auth/updateManagedUser',
  async (updateManagedUserDto: UpdateManagedUserDto) => {
    const managedUsers = await apiRequest<User>(
      'POST',
      `/user/managedUser/update`,
      undefined,
      {
        ...updateManagedUserDto,
      },
    );

    return await managedUsers;
  },
);

export const createManagedUser = createAsyncThunk(
  'auth/add/managedUser',
  async (createManagedUserDto: CreateManagedUserDto) => {
    const managedUsers = await apiRequest<User>(
      'POST',
      `/user/add/managedUser`,
      undefined,
      {
        ...createManagedUserDto,
      },
    );

    return await managedUsers;
  },
);

export const deleteManagedUser = createAsyncThunk(
  'auth/deleteManagedUser',
  async (deleteManagedUserDto: DeleteManagedUserDto) => {
    const user = await apiRequest<User>(
      'DELETE',
      `/user/managedUser/delete`,
      undefined,
      {
        ...deleteManagedUserDto,
      },
    );

    return await user;
  },
);

export const sendMailToUserFunction = createAsyncThunk(
  'user/sendMailToUser',
  async (sendMailToUserDto: SendMailToUserDto) => {
    const state = await apiRequest<boolean>(
      'POST',
      '/user/sendMailToUser',
      undefined,
      {
        ...sendMailToUserDto,
      },
    );
    return state;
  },
);

/** Admin thunks **/

export const adminGetAllUsers = createAsyncThunk(
  'auth/admin/getAllUsers',
  async () => {
    const result = await apiRequest<User[]>('GET', '/auth/admin/users');

    return result;
  },
);

export const addAdminAccount = createAsyncThunk(
  'auth/admin/addAdmin',
  async (userId: string) => {
    const result = await apiRequest<User>('POST', `/auth/admin/${userId}`);

    return result;
  },
);

export const undoAdminAccount = createAsyncThunk(
  'auth/admin/deleteAdmin',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'POST',
      `/auth/admin/delete/admin/${userId}`,
    );

    return result;
  },
);

export const activateAccount = createAsyncThunk(
  'auth/admin/activateAccount',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'PUT',
      `/auth/admin/${userId}/activate`,
    );

    return result;
  },
);

export interface UpdatePluginPosition {
  userId: string;
  position: string;
}

export interface UpdateUserCompanyRole {
  userId: string;
  company?: string;
  position?: string;
}

export interface CreateManagedUserDto {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profilePictureUrl?: string;
  companyLogo?: string;
  company?: string;
  position?: string;
  email?: string;
  website?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  associatedTag?: string;
  address?: string;
}

export interface DeleteManagedUserDto {
  userId?: string;
  managedUserId?: string;
}

export const deleteAccount = createAsyncThunk(
  'auth/admin/deleteAccount',
  async (userId: string) => {
    const result = await apiRequest<User>('DELETE', `/auth/admin/${userId}`);

    return result;
  },
);

export const getCompanyLogo = createAsyncThunk(
  '/user/companyLogo',
  async (userId: string) => {
    const result = await apiRequest<User>(
      'GET',
      `/user/companyLogo/${userId}`,
      undefined,
    );

    return result;
  },
);

/* Shared reducers */
const signInUpPendingReducer = (state: AuthState) => {
  state.isLogged = false;
  state.isLoading = true;
  state.error = null;
  state.user = null;
};

const signInUpFulfilledReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLogged = true;
  state.isLoading = false;
  state.user = payload;
  state.isAdmin = payload?.isAdmin;
};

const pendingReducer = (state: AuthState) => {
  state.isLoading = true;
  state.error = null;
};

const fulfilledReducer = (state: AuthState) => {
  state.isLoading = false;
};

const rejectedReducer = (
  state: AuthState,
  { error }: { error: SerializedError },
) => {
  state.isLoading = false;
  state.error = error.message || 'error';
};

/* Slice */

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.isLogged = false;
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state: AuthState) => {
        state.isInitialLoading = true;
      })

      .addCase(fetchCurrentUser.fulfilled, (state: AuthState, { payload }) => {
        state.isInitialLoading = false;
        state.user = payload;
        state.isLogged = !!payload;
        state.isAdmin = payload?.isAdmin || false;
      })
      .addCase(
        fetchCurrentUserStats.fulfilled,
        (state: AuthState, { payload }) => {
          state.user = payload;
        },
      )
      .addCase(signIn.pending, signInUpPendingReducer)
      .addCase(signIn.fulfilled, signInUpFulfilledReducer)
      .addCase(signIn.rejected, rejectedReducer)
      .addCase(signUp.pending, signInUpPendingReducer)
      .addCase(signUp.fulfilled, signInUpFulfilledReducer)
      .addCase(signUp.rejected, rejectedReducer)
      .addCase(signInUpGoogle.pending, signInUpPendingReducer)
      .addCase(signInUpGoogle.fulfilled, signInUpFulfilledReducer)
      .addCase(signInUpGoogle.rejected, rejectedReducer)
      .addCase(sendLostPassword.pending, pendingReducer)
      .addCase(sendLostPassword.fulfilled, fulfilledReducer)
      .addCase(sendLostPassword.rejected, rejectedReducer)
      .addCase(useLostPasswordToken.pending, pendingReducer)
      .addCase(useLostPasswordToken.fulfilled, signInUpFulfilledReducer)
      .addCase(useLostPasswordToken.rejected, rejectedReducer)
      .addCase(useActivationAccount.pending, pendingReducer)
      .addCase(useActivationAccount.fulfilled, signInUpFulfilledReducer)
      .addCase(useActivationAccount.rejected, rejectedReducer)
      .addCase(confirmManagedUser.pending, pendingReducer)
      .addCase(confirmManagedUser.fulfilled, signInUpFulfilledReducer)
      .addCase(confirmManagedUser.rejected, rejectedReducer)
      .addCase(adminGetAllUsers.fulfilled, (state: AuthState, { payload }) => {
        state.users = payload;
      })
      .addCase(deleteAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.filter((user) => user._id !== payload._id);
      })
      .addCase(addAdminAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(undoAdminAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(activateAccount.fulfilled, (state: AuthState, { payload }) => {
        state.users = state.users.map((user) =>
          user._id !== payload._id ? user : payload,
        );
      })
      .addCase(
        findAllManagedUsers.fulfilled,
        (state: AuthState, { payload }) => {
          state.managedUsers = payload;
        },
      )
      .addCase(updateManagedUser.fulfilled, (state: AuthState, { payload }) => {
        if (state.user) {
          // Mettre à jour l'utilisateur complet dans le state avec le payload
          state.user = payload;
        }
      })
      .addCase(createManagedUser.fulfilled, (state: AuthState, { payload }) => {
        if (state.user) {
          // Mettre à jour l'utilisateur complet dans le state avec le payload
          state.user = payload;
        }
      })
      .addCase(deleteManagedUser.fulfilled, (state: AuthState, { payload }) => {
        if (state.user) {
          // Mettre à jour l'utilisateur complet dans le state avec le payload
          state.user = payload;
        }
      });
  },
  initialState,
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
