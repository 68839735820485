import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import {
  SideBarHandleContext,
  UserEditionHandleContext,
} from 'src/hooks/context';
import { useAppDispatch } from 'src/hooks/store';
import PlusIcon from '../../assets/plus.png';
import { ManagedUser, User } from 'src/slices/auth';
import { Tag, addNewTag, deleteTag, updateTag } from 'src/slices/tag';
import TagsListCard from './tagsListCard';
import { Button, Typography } from '@mui/material';

const useStyles = createUseStyles({
  cardTag: {
    background: 'white',
    borderRadius: '8px',
    width: '428px',
    minWidth: '428px',
    maxWidth: '428px',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '18px',
    boxShadow: '0px 10px 30px 0px rgba(3, 3, 37, 0.10)',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: 'none',
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFFFFF',
      borderRadius: '60px',
      opacity: '0.5',
      display: 'none',
    },
  },
  cardAddTag: {
    background: 'rgba(255, 255, 255, 0.50)',
    width: '427px',
    height: '100%',
    border: '1px dashed var(--Blue-3, #56CCF2);',
    borderRadius: '8px',
    minWidth: '427px',
    maxWidth: '427px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '30px',
    justifyContent: 'center',
  },
  tagName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '140%',
    color: '#060640',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    cursor: 'pointer',
  },
  moreOptionButton: {
    borderRadius: '50px',
    color: '#273EAC',
    width: '25px',
    height: '25px',
    justifyContent: 'flex-end',
  },
  textFieldEditTag: {
    '& input': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#060640',
    },

    '& .MuiOutlinedInput-root': {
      width: '190px',
      height: '32px',
      borderRadius: '8px',
      border: '1px solid #CED3E8',
      background: 'white',
    },
    '& .MuiOutlinedInput-hover': {
      border: '1px solid #CED3E8',
    },
    '& .MuiOutlinedInput-focus': {
      border: '1px solid #CED3E8',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  tagEditionContainer: {
    display: 'flex',
    flex: 1,
    gap: '10px',
  },

  saveButtonTagEdition: {
    color: 'white',
    height: '32px',
    width: '40px',
    borderRadius: '8px',
    background: '#273EAC',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '-0.4px',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
  },
  listContainer: {
    gap: '30px',
    maxHeight: 'calc(100vh - 20px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    overflowX: 'scroll',
    overflowY: 'visible',
    height: '100%',
    paddingBottom: '30px',
    paddingTop: '24px',
    paddingLeft: '20px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  buttonScrollLeft: {
    width: '80px',
    height: '80px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: '#273EAC',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '252px',
    rotate: '180deg',
  },
  buttonScrollRight: {
    width: '80px',
    height: '80px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: '#273EAC',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    right: '30px',
  },
  addPageTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#273EAC',
  },
  teamCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },

  editNameContainer: {
    display: 'flex',
    width: '100%',
    background: 'rgba(237, 240, 255, 0.60)',
    borderRadius: '8px',
    height: '48px',
    padding: '8px 12px 8px 16px',
    alignItems: 'center',
    marginBottom: '16px',
    justifyContent: 'space-between',
  },

  menu: {
    zIndex: 2000,
    '& .MuiMenu-paper': {
      borderRadius: '8px',
      boxShadow: '0px 10px 10px 0px rgba(3, 3, 37, 0.10)',
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '140%',
      color: '#060640',
    },
  },

  addUserContainer: {
    display: 'flex',
    width: '100%',
    height: '120px',
    background: 'rgba(237, 240, 255, 0.60)',
    borderRadius: '8px',
    padding: '8px 12px 8px 16px',
    alignItems: 'center',
    marginBottom: '16px',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
});

interface Props {
  user: User | null;
  tags: Tag[];
  managedUsers: ManagedUser[];
}

const TagList: React.FC<Props> = ({ user, tags, managedUsers }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const sidebarContext = useContext(SideBarHandleContext);

  const userEditionContext = useContext(UserEditionHandleContext);

  const [refs, setRefs] = useState<RefObject<HTMLDivElement>[]>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTagToDelete('');
  };

  const [isEditingTagName, setIsEditingTagName] = useState<boolean[]>([]);

  useEffect(() => {
    const temp: boolean[] = tags.map((tag) => tag.name === '');
    setIsEditingTagName(temp);
  }, [tags]);

  useEffect(() => {
    setRefs(
      [...new Array(tags.length + 1)].map(() =>
        React.createRef<HTMLDivElement>(),
      ),
    );
  }, [tags.length]);

  const [maxSlideRight, setMaxSlideRight] = useState(false);

  const [maxSlideLeft, setMaxSlideLeft] = useState(true);

  const scrollToElement = (direction: string) => {
    if (refs) {
      let wait = false;
      if (direction === 'right') {
        refs.some((ref) => {
          if (ref.current) {
            const elementRect = ref.current?.getBoundingClientRect();
            const windowWidth = window.innerWidth;

            if (!(elementRect?.left < windowWidth && elementRect?.right >= 0)) {
              if (wait) {
                ref.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                  inline: 'nearest',
                });
                return ref;
              }
            } else {
              wait = true;
            }
          }
        });
      } else {
        refs
          .slice(0)
          .reverse()
          .some((ref) => {
            if (ref.current) {
              const elementRect = ref.current?.getBoundingClientRect();
              const windowWidth = window.innerWidth;

              if (
                !(elementRect?.left < windowWidth && elementRect?.right >= 0)
              ) {
                if (wait) {
                  ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                  });
                  return ref;
                }
              } else {
                wait = true;
              }
            }
          });
      }
    }
  };

  const handleScroll = () => {
    if (refs) {
      if (refs[refs.length - 1]?.current) {
        const elementRect =
          refs[refs.length - 1]?.current?.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (elementRect) {
          if (elementRect?.left < windowWidth && elementRect?.right >= 0) {
            setMaxSlideRight(true);
          } else {
            setMaxSlideRight(false);
          }
        }
      }

      if (refs[0].current) {
        const elementRect = refs[0]?.current?.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (elementRect) {
          if (elementRect?.left < windowWidth && elementRect?.right >= 0) {
            setMaxSlideLeft(true);
          } else {
            setMaxSlideLeft(false);
          }
        }
      }
    }
  };

  const [tagToUpdate, setTagToUpdate] = useState<string>('');

  const updateTagName = async (idTag: string) => {
    await dispatch(
      updateTag({
        id: idTag,
        name: tagToUpdate,
      }),
    ).then((e) => {
      if (e.payload) {
        setTagToUpdate('');
      }
    });
  };

  const deleteTagFunction = async () => {
    if (user)
      await dispatch(deleteTag({ idTag: tagToDelete, idUser: user._id }));
  };

  const [tagToDelete, setTagToDelete] = useState('');

  const ref = useRef<
    HTMLUListElement | undefined
  >() as React.MutableRefObject<HTMLUListElement>;
  useEffect(() => {
    if (ref && tags.length != 0) {
      ref.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (ref && tags.length != 0) {
        ref.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref, refs]);

  const addNewTagFunction = () => {
    dispatch(addNewTag({ name: 'New team' }));
  };

  const openUserEditionSidebar = (tag: Tag) => {
    sidebarContext?.setOpenUserEdition(true);
    userEditionContext?.setIsNewUser(true);
    userEditionContext?.setAssociatedTag(tag);
  };

  return user != undefined ? (
    <>
      <ul className={classes.listContainer} id="listContainer" ref={ref}>
        {tags.length != 0 &&
          tags.map((item, indexTag) => (
            <React.Fragment key={indexTag}>
              <div
                className={classes.cardTag}
                ref={refs ? refs[indexTag] : undefined}
              >
                <div className={classes.teamCardContainer}>
                  <div className={classes.editNameContainer}>
                    {isEditingTagName[indexTag] === false ? (
                      <ButtonBase
                        onClick={() => {
                          setIsEditingTagName((prev) => {
                            const temp = [...prev];
                            temp[indexTag] = true;
                            return temp;
                          });
                          setTagToUpdate(item.name);
                        }}
                        className={classes.tagName}
                      >
                        {item.name}
                      </ButtonBase>
                    ) : (
                      <div className={classes.tagEditionContainer}>
                        <TextField
                          className={classes.textFieldEditTag}
                          placeholder={tagToUpdate}
                          value={tagToUpdate}
                          onChange={(e) => {
                            setTagToUpdate(e.target.value);
                          }}
                        />

                        <ButtonBase
                          className={classes.saveButtonTagEdition}
                          onClick={() => {
                            updateTagName(item._id).finally(() => {
                              setIsEditingTagName((prev) => {
                                const temp = [...prev];
                                temp[indexTag] = false;
                                return temp;
                              });
                            });
                          }}
                        >
                          Ok
                        </ButtonBase>
                      </div>
                    )}

                    <ButtonBase
                      className={`${classes.moreOptionButton}`}
                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => {
                        handleClick(event);
                        setTagToDelete(item._id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z"
                          fill="#060640"
                        />
                      </svg>
                    </ButtonBase>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      style={{ zIndex: '2000' }}
                      className={classes.menu}
                      disablePortal
                    >
                      <MenuItem
                        onClick={() => {
                          deleteTagFunction();
                          handleClose();
                        }}
                        style={{ color: 'red' }}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </Menu>
                  </div>
                  <TagsListCard
                    tag={item}
                    managedUsers={managedUsers.filter((managedUser) => {
                      if (managedUser.tag)
                        return managedUser.tag._id === item._id;
                    })}
                  />
                  <ButtonBase
                    className={classes.addUserContainer}
                    onClick={() => {
                      openUserEditionSidebar(item);
                    }}
                  >
                    <img src={`/static/icons/plusIcon.svg`} />
                    <Typography
                      className={classes.addPageTitle}
                      style={{ color: '#060640' }}
                    >
                      {t('pages.Teams.addUser')}
                    </Typography>
                  </ButtonBase>
                </div>
              </div>
            </React.Fragment>
          ))}

        <div
          className={`${classes.cardAddTag}`}
          ref={refs ? refs[tags.length] : undefined}
        >
          <Button
            onClick={() => {
              addNewTagFunction();
            }}
          >
            <img src={`/static/icons/plusIcon.svg`} />
          </Button>

          <Typography className={classes.addPageTitle}>
            {t('pages.Teams.addTag')}
          </Typography>
        </div>
      </ul>
      <>
        {!maxSlideRight && (
          <ButtonBase
            className={classes.buttonScrollRight}
            onClick={() => {
              scrollToElement('right');
            }}
          >
            <img src={PlusIcon} />
          </ButtonBase>
        )}
        {!maxSlideLeft && (
          <ButtonBase
            onClick={() => {
              scrollToElement('left');
            }}
            className={classes.buttonScrollLeft}
          >
            <img src={PlusIcon} />
          </ButtonBase>
        )}
      </>
    </>
  ) : (
    <></>
  );
};

export default TagList;
