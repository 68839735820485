import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Tag as TagModel, fetchAllTags } from 'src/slices/tag';
import { tagsSelector } from 'src/store';

const useStyles = createUseStyles({
  tagPage: {
    paddingTop: '30px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
});

const Tag = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const tags: TagModel[] = useAppSelector(tagsSelector.selectAll);

  useEffect(() => {
    if (user) {
      dispatch(fetchAllTags());
    }
  }, [user]);

  return user === null ? (
    <></>
  ) : (
    <div className={classes.tagPage}>
      {tags.map((tag, index) => (
        <div key={index}>hello la team {tag.name}</div>
      ))}
    </div>
  );
};

export default Tag;
