import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import {
  ManagedUser,
  deleteManagedUser,
  sendMailToUserFunction,
} from 'src/slices/auth';
import { ButtonBase } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  SideBarHandleContext,
  UserEditionHandleContext,
} from 'src/hooks/context';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import PendingIcon from '@mui/icons-material/Pending';

const useStyles = createUseStyles({
  menuPopOver: {
    zIndex: 1700,
    '& .MuiDialog-paper': {
      width: 'auto',
      borderRadius: '8px',
      background: 'white',
      paddingTop: '50px',
      paddingBottom: '30px',
      paddingLeft: '58px',
      paddingRight: '58px',
      maxWidth: '100%',
    },
  },
  teamCardActionsEdit: {
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    '& .MuiButtonBase-root': {
      width: '25px',
      height: '25px',
    },
  },
  teamCardContainer: {
    width: '100%',
    height: '120px',
    borderRadius: '8px',
    background: 'rgba(237, 240, 255, 0.60)',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px 8px 16px',
    justifyContent: 'space-between',
    position: 'relative',
  },
  idleButton: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  video: {
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: '#F7F8FD',
    width: '90px',
    height: '75px',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  contactText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '140%',
    color: '#060640',
  },
  contactContainer: {
    paddingTop: '4px',
    marginBottom: '8px',
  },
  videoIconImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  contentTeam: {
    width: '201px',
    display: 'flex',
  },
});

interface Props {
  managedUser: ManagedUser;
}

const TeamCard: React.FC<Props> = ({ managedUser }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const sidebarContext = useContext(SideBarHandleContext);
  const userEditionContext = useContext(UserEditionHandleContext);

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editUser = () => {
    sidebarContext?.setOpenUserEdition(true);
    userEditionContext?.setIsNewUser(false);
    userEditionContext?.setManagedUser(managedUser);
  };

  const deleteUser = async () => {
    await dispatch(
      deleteManagedUser({
        userId: user?._id,
        managedUserId: managedUser.user._id,
      }),
    );
  };

  const [sendingMail, setSendingMail] = useState<boolean>(false);

  const sendMailToUser = async () => {
    await dispatch(
      sendMailToUserFunction({
        managerUser: user?._id,
        managedUser: managedUser.user._id,
      }),
    ).then((e) => {
      setSendingMail(e.payload as boolean);
    });
  };

  const cardActions = (
    <div className={classes.idleButton}>
      <ButtonBase className={classes.teamCardActionsEdit} onClick={handleOpen}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z"
            fill="#060640"
          />
        </svg>
      </ButtonBase>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.menuPopOver}
      >
        <MenuItem
          onClick={() => {
            editUser();
            handleClose();
          }}
        >
          {t('common.edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            deleteUser();
            handleClose();
          }}
          style={{ color: 'red' }}
        >
          {t('common.delete')}
        </MenuItem>
      </Menu>
      {managedUser.state ? (
        <DoneIcon />
      ) : sendingMail ? (
        <PendingIcon />
      ) : (
        <ButtonBase
          className={classes.teamCardActionsEdit}
          onClick={() => {
            sendMailToUser();
          }}
        >
          <SendIcon />
        </ButtonBase>
      )}
    </div>
  );

  return (
    <div key={managedUser.user._id} className={classes.teamCardContainer}>
      <div className={classes.content}>
        <div className={`${classes.contactContainer}`}>
          <Typography className={classes.contactText}>
            {managedUser.user.firstName} {managedUser.user.lastName} -{' '}
            {managedUser.user.position}
          </Typography>
        </div>

        <div className={`${classes.contentTeam}`}>
          <div className={classes.video}>
            <div style={{ position: 'relative' }}>
              {managedUser?.user.profilePictureUrl ? (
                <img
                  src={managedUser?.user.profilePictureUrl}
                  className={classes.videoIconImg}
                />
              ) : (
                <AccountCircleOutlinedIcon className={classes.videoIconImg} />
              )}
            </div>
          </div>
        </div>
      </div>
      {cardActions}
    </div>
  );
};

export default TeamCard;
