import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import {
  SideBarHandleContext,
  SignatureSelectorHandleContext,
} from 'src/hooks/context';
import { ButtonBase, Drawer } from '@mui/material';

import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import SignatureSelectorBody from 'src/components/signature/signatureSelectorBody';
import { addNewSignature } from 'src/slices/signature';

const drawerWidth = '700px';

const useStyles = createUseStyles({
  sideBar: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '700px',
      },
      overflowY: 'scroll',
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '&::-webkit-scrollbar ': {
        display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
      },
    },
    zIndex: '2200 !important',
    '& .MuiBackdrop-root': {
      background: '#101F41',
      opacity: '0.80 !important',
    },
  },

  sideBarContainer: {
    flex: 1,
    background: '#F7F8FD',
    paddingTop: '40px',
    paddingLeft: '24px',
    paddingRight: '32px',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar ': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
  sideBarFooter: {
    height: '80px',
    background: '#EDF0FF',
    padding: '16px 48px 16px 48px',
    flexShrink: 0,
  },
  publishButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#273EAC',
    borderRadius: '8px',
    color: 'white',
    width: '100%',
    height: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '100%',
  },
});

const SignatureSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const sidebarContext = useContext(SideBarHandleContext);
  const signatureSelectorContext = useContext(SignatureSelectorHandleContext);
  const [checked, setChecked] = useState([false, false, false, false]);
  const [body, setBody] = useState('');

  const save = async () => {
    if (
      checked.findIndex((value) => value === true) + 1 &&
      signatureSelectorContext?.tag
    ) {
      dispatch(
        addNewSignature({
          body: body,
          model: checked.findIndex((value) => value === true) + 1,
          tagId: signatureSelectorContext?.tag?._id,
        }),
      );
    }
  };

  const closeTestimionialsSelectorSidebar = () => {
    sidebarContext?.setOpenSignatureSelector(false);
  };

  return signatureSelectorContext?.tag ? (
    <Drawer
      className={classes.sideBar}
      variant="temporary"
      anchor="right"
      open={sidebarContext?.isSignatureSelectorOpened}
      onClose={() => closeTestimionialsSelectorSidebar()}
    >
      <div className={`${classes.sideBarContainer}`}>
        <SignatureSelectorBody
          tag={signatureSelectorContext?.tag}
          checked={checked}
          setChecked={setChecked}
          setBody={setBody}
        />
      </div>
      <div className={`${classes.sideBarFooter}`}>
        <ButtonBase
          className={classes.publishButton}
          onClick={() => {
            save();
            closeTestimionialsSelectorSidebar();
          }}
        >
          {t('common.save')}
        </ButtonBase>
      </div>
    </Drawer>
  ) : (
    <></>
  );
};

export default SignatureSelector;
