import React, { useEffect, useState } from 'react';
import { ManagedUser } from 'src/slices/auth';
import { Signature } from 'src/slices/signature';
import {
  SideBarHandleContext,
  SignatureEditionHandleContext,
  SignatureSelectorHandleContext,
  UserEditionHandleContext,
} from './context';
import { Tag } from 'src/slices/tag';

interface Props {
  children: React.ReactNode;
}

const SideBarProvider: React.FC<Props> = ({ children }) => {
  /** SidebarHandler **/
  const [isUserEditionOpened, setOpenUserEdition] = useState<boolean>(false);
  const [isSignatureEditionOpened, setOpenSignatureEdition] =
    useState<boolean>(false);
  const [isSignatureSelectorOpened, setOpenSignatureSelector] =
    useState<boolean>(false);

  /** Team Edition **/
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [managedUser, setManagedUser] = useState<ManagedUser | undefined>(
    undefined,
  );

  const [associatedTag, setAssociatedTag] = useState<Tag | undefined>(
    undefined,
  );

  /** Signature Edition **/
  const [isNewSignature, setIsNewSignature] = useState<boolean>(false);
  const [signature, setSignature] = useState<Signature | undefined>(undefined);

  const [tag, setTag] = useState<Tag | undefined>(undefined);

  /** Signature Selector **/

  useEffect(() => {
    const handlePopState = () => {
      setOpenSignatureEdition(false);
      setOpenUserEdition(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      <SideBarHandleContext.Provider
        value={{
          isUserEditionOpened: isUserEditionOpened,
          isSignatureEditionOpened: isSignatureEditionOpened,
          setOpenUserEdition: setOpenUserEdition,
          setOpenSignatureEdition: setOpenSignatureEdition,
          isSignatureSelectorOpened: isSignatureSelectorOpened,
          setOpenSignatureSelector: setOpenSignatureSelector,
        }}
      >
        <UserEditionHandleContext.Provider
          value={{
            isNewUser: isNewUser,
            setIsNewUser: setIsNewUser,
            managedUser: managedUser,
            setManagedUser: setManagedUser,
            associatedTag: associatedTag,
            setAssociatedTag: setAssociatedTag,
          }}
        >
          <SignatureSelectorHandleContext.Provider
            value={{
              signature: signature,
              setSignature: setSignature,
              tag: tag,
              setTag: setTag,
            }}
          >
            <SignatureEditionHandleContext.Provider
              value={{
                isNewSignature: isNewSignature,
                setIsNewSignature: setIsNewSignature,
                signature: signature,
                setSignature: setSignature,
                tag: tag,
                setTag: setTag,
              }}
            >
              {children}
            </SignatureEditionHandleContext.Provider>
          </SignatureSelectorHandleContext.Provider>
        </UserEditionHandleContext.Provider>
      </SideBarHandleContext.Provider>
    </>
  );
};

export default SideBarProvider;
