import React, { useEffect, useState } from 'react';
import {
  Avatar,
  ButtonBase,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import {
  confirmManagedUser,
  ConfirmManagedUserDto,
  fetchUserById,
  User,
} from 'src/slices/auth';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { handleNotification } from 'src/helpers/notifications';
import { useSnackbar } from 'notistack';
import { UploadResultImage, uploadFileImage } from 'src/slices/file';
import { Flex } from 'src/components/basic/Flex';
import { theme } from 'src/constants/theme';
import { TextFieldWrapper } from 'src/components/basic/TextFieldWrapper';
import { Upload } from 'src/components/basic/Upload';
import { useParams } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DoneIcon from '@mui/icons-material/Done';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = createUseStyles({
  settingsPage: {
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px -1px 1px 0px inset, rgba(50, 50, 93, 0.1) 0px -50px 100px -20px, rgba(0, 0, 0, 0.2) 0px -30px 60px -30px',
    flexDirection: 'column',
    background: 'white',
    justifyContent: 'space-between',
    borderRadius: '8px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  settingsContainer: {
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  buttonWrapper: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    flexDirection: 'row',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '20px',
  },

  tooltipContainer: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
  },

  previousButton: {
    fontFamily: 'Montserrat',
    fontSize: '10px',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  button: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    background: '#273EAC',
    color: 'white',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
    flex: 1,
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  textFieldStyle: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CED3E8',
      },
      '& input': {
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '15px',
        fontStyle: 'normal',
      },
      '&:hover fieldset': {
        borderColor: '#273EAC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#273EAC',
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '15px',
      fontStyle: 'normal',
      '&.Mui-focused': {
        color: '#273EAC',
      },
    },
    '& .fieldset': {
      border: 'none',
    },
    marginBottom: '8px',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    alignItems: 'center',
    flex: 1,
  },
  iconDone: {
    fontSize: '12px',
    color: 'rgb(106,228,179)',
  },
  icon: {
    fontSize: '12px',
    color: '#7E7E7E',
  },

  typographyClasse: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    fontSize: '10px',
    color: '#7E7E7E',
  },
  verificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
});

const SignUpManagedUser = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [user, setUser] = useState<User>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { managedUserId, managerUserId } = useParams();

  const [showPassword, setShowPassword] = useState(false);

  const [isUpperCase, setIsUpperCase] = useState(false);

  const [isLowerCase, setIsLowerCase] = useState(false);

  const [isNumber, setIsNumber] = useState(false);

  const [minLength, setMinLength] = useState(false);

  useEffect(() => {
    if (managedUserId) {
      dispatch(fetchUserById(managedUserId)).then((e) => {
        setUser(e.payload as User);
      });
    }
  }, [managedUserId, dispatch]);

  const userSettingsValidationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    company: yup.string(),
    password: yup.string().min(
      10,
      t('forms.rules.min2', {
        value: 8,
      }),
    ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('forms.errorConfirmPasswordNotMatch')),
    profilePictureUrl: yup.string(),
    companyLogo: yup.string(),
    phoneNumber: yup.string(),
    website: yup.string(),
    facebook: yup.string(),
    linkedin: yup.string(),
    twitter: yup.string(),
    address: yup.string(),
  });

  const userSettingsInitialValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    company: user?.company ?? '',
    position: user?.position ?? '',
    password: '',
    confirmPassword: '',
    phoneNumber: user?.phoneNumber ?? '',
    profilePictureUrl: user?.profilePictureUrl ?? '',
    companyLogo: user?.companyLogo ?? '',
    website: user?.website ?? '',
    facebook: user?.facebook ?? '',
    linkedin: user?.linkedin ?? '',
    twitter: user?.twitter ?? '',
    address: user?.address ?? '',
  };

  const userSettingsFormikOnSubmit = async (values: {
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    companyLogo: string;
    profilePictureUrl: string;
    website: string;
    facebook: string;
    linkedin: string;
    twitter: string;
    address: string;
  }) => {
    if (userSettingsFormik.dirty) {
      const updatedValues: ConfirmManagedUserDto = {
        managerUserId: managerUserId,
        managedUserId: user?._id,
        firstName:
          user?.firstName !== values.firstName ? values.firstName : undefined,
        lastName:
          user?.lastName !== values.lastName ? values.lastName : undefined,
        company: user?.company !== values.company ? values.company : undefined,
        position:
          user?.position !== values.position ? values.position : undefined,
        authProviders:
          values.password !== '' && values.password === values.confirmPassword
            ? { local: values.password }
            : undefined,
        phoneNumber:
          user?.phoneNumber !== values.phoneNumber
            ? values.phoneNumber
            : undefined,
        profilePictureUrl:
          user?.profilePictureUrl !== values.profilePictureUrl
            ? values.profilePictureUrl
            : undefined,
        companyLogo:
          user?.companyLogo !== values.companyLogo
            ? values.companyLogo
            : undefined,
        website: user?.website !== values.website ? values.website : undefined,
        facebook:
          user?.facebook !== values.facebook ? values.facebook : undefined,
        linkedin:
          user?.linkedin !== values.linkedin ? values.linkedin : undefined,
        twitter: user?.twitter !== values.twitter ? values.twitter : undefined,
        address: user?.address !== values.address ? values.address : undefined,
      };

      const isChangingValue = Object.keys(updatedValues).some(
        (k) => (updatedValues as any)[k] !== undefined,
      );

      isChangingValue &&
        (await handleNotification(
          dispatch(confirmManagedUser(updatedValues)),
          t('pages.Settings.successProfileUpdate'),
          t('pages.Settings.errorProfileUpdate'),
          enqueueSnackbar,
          closeSnackbar,
        ));

      return isChangingValue;
    }
  };

  useEffect(() => {
    if (user) {
      return userSettingsFormik.resetForm({
        values: {
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          phoneNumber: user.phoneNumber ?? '',
          profilePictureUrl: user.profilePictureUrl ?? '',
          company: user.company ?? user?.company ?? '',
          companyLogo: user.companyLogo ?? user?.companyLogo ?? '',
          position: user.position ?? '',
          website: user.website ?? '',
          facebook: user.facebook ?? '',
          linkedin: user.linkedin ?? '',
          twitter: user.twitter ?? '',
          address: user.address ?? '',
          password: '',
          confirmPassword: '',
        },
      });
    }
  }, [user]);

  const userSettingsFormik = useFormik({
    initialValues: userSettingsInitialValues,
    validationSchema: userSettingsValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: userSettingsFormikOnSubmit,
  });

  const saveSettings = async (event: any) => {
    await userSettingsFormik.submitForm();
    event.preventDefault();
  };

  const uploadOnChange = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          userSettingsFormik.setFieldValue('profilePictureUrl', payload.url);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadOnChangeCompanyLogo = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await dispatch(
        uploadFileImage({
          formData,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        const payload: UploadResultImage = result.payload as UploadResultImage;

        if (payload) {
          userSettingsFormik.setFieldValue('companyLogo', payload.url);
        } else {
          console.error('Error: Missing "url" in payload:', payload);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const removeFileFunction = async (formikField: string) => {
    userSettingsFormik.setFieldValue(formikField, '');
  };

  const [disableButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (!isUpperCase || !isLowerCase || !isNumber || !minLength) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [isUpperCase, isLowerCase, isNumber, minLength]);

  useEffect(() => {
    if (userSettingsFormik.values.password.match(/[A-Z]/g)) {
      setIsUpperCase(true);
    } else {
      setIsUpperCase(false);
    }
    if (userSettingsFormik.values.password.match(/[a-z]/g)) {
      setIsLowerCase(true);
    } else {
      setIsLowerCase(false);
    }
    if (userSettingsFormik.values.password.match(/[0-9]/g)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
    if (userSettingsFormik.values.password.length >= 8) {
      setMinLength(true);
    } else {
      setMinLength(false);
    }
  }, [userSettingsFormik.values.password]);

  return user === null || user?.isActiveAccount ? (
    <></>
  ) : (
    <div
      title="Register | Kudeo-Sign"
      style={{
        height: '100%',
        backgroundImage: `url('/background.svg')`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={classes.settingsPage}>
          <div className={classes.settingsContainer}>
            <div className={classes.cardContainer}>
              <TextField
                margin="dense"
                id="firstName"
                className={classes.textFieldStyle}
                label={`${t('common.firstName')}`}
                type="text"
                fullWidth
                variant="outlined"
                name="firstName"
                value={userSettingsFormik.values.firstName}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  userSettingsFormik.touched.firstName &&
                  Boolean(userSettingsFormik.errors.firstName)
                }
                helperText={
                  userSettingsFormik.touched.firstName &&
                  userSettingsFormik.errors.firstName
                }
              />
              <TextField
                margin="dense"
                id="lastName"
                className={classes.textFieldStyle}
                label={`${t('common.lastName')}`}
                type="text"
                fullWidth
                variant="outlined"
                name="lastName"
                value={userSettingsFormik.values.lastName}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  userSettingsFormik.touched.lastName &&
                  Boolean(userSettingsFormik.errors.lastName)
                }
                helperText={
                  userSettingsFormik.touched.lastName &&
                  userSettingsFormik.errors.lastName
                }
              />
              <TextField
                margin="dense"
                id="phoneNumber"
                className={classes.textFieldStyle}
                label={`${t('common.phoneNumber')}`}
                type="phoneNumber"
                fullWidth
                variant="outlined"
                name="phoneNumber"
                value={userSettingsFormik.values.phoneNumber}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  userSettingsFormik.touched.phoneNumber &&
                  Boolean(userSettingsFormik.errors.phoneNumber)
                }
                helperText={
                  userSettingsFormik.touched.phoneNumber &&
                  userSettingsFormik.errors.phoneNumber
                }
              />

              <TextField
                margin="dense"
                id="address"
                className={classes.textFieldStyle}
                label={`${t('common.address')}`}
                type="text"
                fullWidth
                variant="outlined"
                name="address"
                value={userSettingsFormik.values.address}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  userSettingsFormik.touched.address &&
                  Boolean(userSettingsFormik.errors.address)
                }
                helperText={
                  userSettingsFormik.touched.address &&
                  userSettingsFormik.errors.address
                }
              />

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
                spacing={2}
              >
                <TextFieldWrapper
                  id="company"
                  label={`${t('common.company')}`}
                  type="text"
                  className={classes.textFieldStyle}
                  fullWidth
                  variant="outlined"
                  name="company"
                  value={userSettingsFormik.values.company}
                  onChange={userSettingsFormik.handleChange}
                  onBlur={userSettingsFormik.handleBlur}
                  error={
                    userSettingsFormik.touched.company &&
                    Boolean(userSettingsFormik.errors.company)
                  }
                  helperText={
                    userSettingsFormik.touched.company &&
                    userSettingsFormik.errors.company
                  }
                />

                <TextFieldWrapper
                  id="position"
                  label={`${t('common.position')}`}
                  type="text"
                  fullWidth
                  variant="outlined"
                  className={classes.textFieldStyle}
                  name="position"
                  value={userSettingsFormik.values.position}
                  onChange={userSettingsFormik.handleChange}
                  onBlur={userSettingsFormik.handleBlur}
                  error={
                    userSettingsFormik.touched.position &&
                    Boolean(userSettingsFormik.errors.position)
                  }
                  helperText={
                    userSettingsFormik.touched.position &&
                    userSettingsFormik.errors.position
                  }
                />
              </Stack>

              <TextField
                margin="dense"
                id="website"
                className={classes.textFieldStyle}
                label={`${t('common.website')}`}
                type="text"
                fullWidth
                variant="outlined"
                name="website"
                value={userSettingsFormik.values.website}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  userSettingsFormik.touched.website &&
                  Boolean(userSettingsFormik.errors.website)
                }
                helperText={
                  userSettingsFormik.touched.website &&
                  userSettingsFormik.errors.website
                }
              />

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ margin: theme.spacing(1, 0, 0.5, 0) }}
                spacing={2}
              >
                <TextFieldWrapper
                  id="facebook"
                  label={`${t('common.facebook')}`}
                  type="text"
                  className={classes.textFieldStyle}
                  fullWidth
                  variant="outlined"
                  name="facebook"
                  value={userSettingsFormik.values.facebook}
                  onChange={userSettingsFormik.handleChange}
                  onBlur={userSettingsFormik.handleBlur}
                  error={
                    userSettingsFormik.touched.facebook &&
                    Boolean(userSettingsFormik.errors.facebook)
                  }
                  helperText={
                    userSettingsFormik.touched.facebook &&
                    userSettingsFormik.errors.facebook
                  }
                />

                <TextFieldWrapper
                  id="linkedin"
                  label={`${t('common.linkedin')}`}
                  type="text"
                  fullWidth
                  variant="outlined"
                  className={classes.textFieldStyle}
                  name="linkedin"
                  value={userSettingsFormik.values.linkedin}
                  onChange={userSettingsFormik.handleChange}
                  onBlur={userSettingsFormik.handleBlur}
                  error={
                    userSettingsFormik.touched.linkedin &&
                    Boolean(userSettingsFormik.errors.linkedin)
                  }
                  helperText={
                    userSettingsFormik.touched.linkedin &&
                    userSettingsFormik.errors.linkedin
                  }
                />

                <TextFieldWrapper
                  id="twitter"
                  label={`${t('common.twitter')}`}
                  type="text"
                  fullWidth
                  variant="outlined"
                  className={classes.textFieldStyle}
                  name="twitter"
                  value={userSettingsFormik.values.twitter}
                  onChange={userSettingsFormik.handleChange}
                  onBlur={userSettingsFormik.handleBlur}
                  error={
                    userSettingsFormik.touched.twitter &&
                    Boolean(userSettingsFormik.errors.twitter)
                  }
                  helperText={
                    userSettingsFormik.touched.twitter &&
                    userSettingsFormik.errors.twitter
                  }
                />
              </Stack>

              <TextField
                margin="dense"
                id="password"
                label={`${t('common.password')}`}
                type={showPassword ? 'text' : 'password'}
                className={classes.textFieldStyle}
                fullWidth
                variant="outlined"
                name="password"
                autoComplete="new-password"
                value={userSettingsFormik.values.password}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={Boolean(userSettingsFormik.errors.password)}
                helperText={userSettingsFormik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword((show) => !show);
                        }}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <div className={`${classes.verificationContainer}`}>
                <div className={classes.iconContainer}>
                  {isUpperCase ? (
                    <DoneIcon className={classes.iconDone} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.icon} />
                  )}

                  <Typography
                    className={`${classes.typographyClasse}`}
                    style={isUpperCase ? { color: 'rgb(106,228,179)' } : {}}
                  >
                    {t('pages.SignUp.uppercase')}
                  </Typography>
                </div>
                <div className={classes.iconContainer}>
                  {isLowerCase ? (
                    <DoneIcon className={classes.iconDone} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.icon} />
                  )}
                  <Typography
                    className={classes.typographyClasse}
                    style={isLowerCase ? { color: 'rgb(106,228,179)' } : {}}
                  >
                    {t('pages.SignUp.lowercase')}
                  </Typography>
                </div>
                <div className={classes.iconContainer}>
                  {isNumber ? (
                    <DoneIcon className={classes.iconDone} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.icon} />
                  )}
                  <Typography
                    className={classes.typographyClasse}
                    style={isNumber ? { color: 'rgb(106,228,179)' } : {}}
                  >
                    {t('pages.SignUp.number')}
                  </Typography>
                </div>
                <div className={classes.iconContainer}>
                  {minLength ? (
                    <DoneIcon className={classes.iconDone} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.icon} />
                  )}
                  <Typography
                    className={classes.typographyClasse}
                    style={minLength ? { color: 'rgb(106,228,179)' } : {}}
                  >
                    {t('pages.SignUp.minLength')}
                  </Typography>
                </div>
              </div>
              <TextField
                margin="dense"
                id="confirmPassword"
                label={`${t('forms.confirmPassword')}`}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                className={classes.textFieldStyle}
                fullWidth
                variant="outlined"
                name="confirmPassword"
                value={userSettingsFormik.values.confirmPassword}
                onChange={userSettingsFormik.handleChange}
                onBlur={userSettingsFormik.handleBlur}
                error={
                  (userSettingsFormik.touched.confirmPassword &&
                    Boolean(userSettingsFormik.errors.confirmPassword)) ||
                  (userSettingsFormik.values.password !== '' &&
                    userSettingsFormik.values.password !==
                      userSettingsFormik.values.confirmPassword)
                }
                helperText={
                  userSettingsFormik.touched.confirmPassword &&
                  userSettingsFormik.errors.confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword((show) => !show);
                        }}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Flex
                style={{ marginTop: theme.spacing(3), alignItems: 'center' }}
              >
                <div
                  style={{
                    marginRight: theme.spacing(2),
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {t('common.profilePicture')} :
                </div>
                <Avatar
                  style={{ marginRight: theme.spacing(2) }}
                  alt="avatar"
                  src={userSettingsFormik.values.profilePictureUrl}
                  aria-label="avatar"
                />
                <Upload
                  onChange={(e) => {
                    if (userSettingsFormik.values.profilePictureUrl === '') {
                      uploadOnChange(e);
                    }
                  }}
                  onClick={() => {
                    if (userSettingsFormik.values.profilePictureUrl !== '') {
                      removeFileFunction('profilePictureUrl');
                    }
                  }}
                  type={
                    userSettingsFormik.values.profilePictureUrl !== ''
                      ? 'button'
                      : undefined
                  }
                  id="profilePictureUpload"
                  file="image"
                />
              </Flex>

              <Flex
                style={{ marginTop: theme.spacing(3), alignItems: 'center' }}
              >
                <div
                  style={{
                    marginRight: theme.spacing(2),
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {t('common.logoCompany')} :
                </div>
                <Avatar
                  style={{ marginRight: theme.spacing(2) }}
                  alt="avatar"
                  src={userSettingsFormik.values.companyLogo}
                  aria-label="avatar"
                />
                <Upload
                  onChange={(e) => {
                    if (userSettingsFormik.values.companyLogo === '') {
                      uploadOnChangeCompanyLogo(e);
                    }
                  }}
                  onClick={() => {
                    if (userSettingsFormik.values.companyLogo !== '') {
                      removeFileFunction('companyLogo');
                    }
                  }}
                  type={
                    userSettingsFormik.values.companyLogo !== ''
                      ? 'button'
                      : undefined
                  }
                  id="companyLogoUpload"
                  file="image"
                />
              </Flex>
            </div>
          </div>
          <div className={`${classes.buttonWrapper}`}>
            <ButtonBase
              onClick={() => {
                window.location.reload();
              }}
              className={`${classes.button}`}
              style={{
                background: 'white',
                color: '#273EAC',
                border: '1px solid #273EAC',
              }}
            >
              {t('common.cancel')}
            </ButtonBase>

            <ButtonBase
              onClick={saveSettings}
              className={classes.button}
              style={
                disableButton
                  ? {
                      color: 'grey',
                      backgroundColor: 'white',
                      border: '1px solid grey',
                    }
                  : {}
              }
              disabled={disableButton}
            >
              {t('common.save')}
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpManagedUser;
