import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link as LinkRouter, useLocation } from 'react-router-dom';
import {
  Box,
  ListItemText,
  List,
  ListItem,
  Typography,
  Avatar,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Roles, adminGetAllUsers, logout } from 'src/slices/auth';
import Page from 'src/components/basic/Page';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonBase from '@mui/material/ButtonBase';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StyleIcon from '@mui/icons-material/Style';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import UserEdition from './userEdition/userEdition';
import SignatureEdition from './signature/signatureEdition';
import SignatureSelector from './signature/signatureSelector';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addSignatureToGmailProcess } from 'src/slices/gmail';

const useStyles = createUseStyles({
  navbarContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#060640',
    padding: '24px 16px 32px 16px',
  },

  navbarListItems: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  topMenuItem: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  botMenuItem: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },

  navbarItem: {
    borderRadius: '10px',
    height: '50px',
    position: 'relative',
    width: '210px',
    paddingLeft: '16px',
  },

  navbarItemText: {
    '& span': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '14px',
    },
  },
  navbarActiveItem: {
    background: 'white',
    '&:hover': {
      background: 'white',
    },
  },

  loggedLayoutContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgba(39, 62, 172, 0.08)',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  pageContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  loggedLayoutNavbar: {
    height: '100%',
    display: 'flex',
    position: 'sticky',
    zIndex: 2,
  },
  loggedLayoutContent: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  loggedLayoutBox: {
    flexDirection: 'column',
    width: '100%',
  },

  headerLayoutNavbar: {
    height: '72px',
    width: '100%',
    zIndex: 2000,
    position: 'sticky',
    boxShadow: '0px 0px 4px rgba(13, 17, 51, 0.2)',
    backgroundColor: 'white',
    display: 'flex',
    padding: '15px 40px 15px 40px',
    gap: '135px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    flex: 1,
    maxWidth: '150px',
  },
  userContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '490px',
    border: 'solid 1px',
  },

  iconStep: {
    marginRight: '9px',
    color: '#273EAC',
  },
});

interface Props {
  children: React.ReactNode;
}

declare global {
  interface Window {
    mainFunction?: (params: any) => void;
  }
}

export const LoggedLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { user, isAdmin } = useAppSelector(({ auth: { user, isAdmin } }) => ({
    user,
    isAdmin,
  }));

  const Linked = ({
    to,
    style,
    children,
  }: {
    to: string;
    style: any;
    children: React.ReactNode;
  }) => {
    return (
      <LinkRouter to={to} style={style}>
        {children}
      </LinkRouter>
    ) as unknown as JSX.Element;
  };

  useEffect(() => {
    if (isAdmin) {
      dispatch(adminGetAllUsers());
    }
  }, []);

  const generateSignature = (user: any, template: string) => {
    return template
      .replace(/{firstName}/g, user?.firstName ?? '')
      .replace(/{lastName}/g, user?.lastName ?? '')
      .replace(/{position}/g, user?.position ?? '')
      .replace(/{profilePictureUrl}/g, user?.profilePictureUrl ?? '')
      .replace(/{phoneNumber}/g, user?.phoneNumber ?? '')
      .replace(/{address}/g, user?.address ?? '')
      .replace(/{website}/g, user?.website ?? '')
      .replace(/{company}/g, user?.company ?? '')
      .replace(/{facebook}/g, user?.facebook ?? '')
      .replace(/{linkedin}/g, user?.linkedin ?? '')
      .replace(/{twitter}/g, user?.twitter ?? '');
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openT = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddSignature = async () => {
    if (user?.tags[0].signature.body) {
      try {
        const signatureTemplate = user?.tags[0].signature.body;
        const signature = generateSignature(user, signatureTemplate);

        // Étape 1 : Vérifier si l'utilisateur est authentifié avec Gmail et récupérer l'URL si nécessaire
        const checkAuthResponse = await dispatch(
          addSignatureToGmailProcess({
            signature: signature,
            userId: user._id,
          }),
        ).unwrap();

        // Étape 2 : Si la réponse contient une URL (l'utilisateur n'est pas encore authentifié)
        if (typeof checkAuthResponse === 'object' && checkAuthResponse.url) {
          // Rediriger l'utilisateur vers Google OAuth
          window.location.href = checkAuthResponse.url;
        } else if (
          typeof checkAuthResponse === 'boolean' &&
          checkAuthResponse === true
        ) {
          // Signature ajoutée avec succès
          console.log('Signature ajoutée avec succès');
        }
      } catch (error) {
        console.error("Erreur lors de l'ajout de la signature à Gmail:", error);
      }
    } else {
      console.log('Aucune signature disponible à ajouter.');
    }
  };

  const stepsTop =
    user?.role === Roles.MANAGER || user?.role === Roles.ADMIN
      ? ['dashboard', 'teams', 'signature']
      : [];

  const stepsBot = ['settings'];

  const friendlyStepsName: { [key: string]: string } = {
    dashboard: t('common.dashboard'),
    settings: t('common.settings'),
    signature: t('common.signature'),
    tags: t('common.tags'),
    teams: t('common.teams'),
  };

  const iconStep = (step: string, isHere: boolean) => (
    <div className={classes.iconStep}>
      {step === 'settings' ? (
        <img src={`/static/icons/settings${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'dashboard' ? (
        <img src={`/static/icons/dashboard${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'offer' ? (
        <img src={`/static/icons/offers${isHere ? 'Blue' : 'White'}.svg`} />
      ) : step === 'tags' ? (
        <StyleIcon sx={isHere ? { color: '#273EAC' } : { color: 'white' }} />
      ) : step === 'signature' ? (
        <EmailIcon sx={isHere ? { color: '#273EAC' } : { color: 'white' }} />
      ) : step === 'teams' ? (
        <GroupsIcon sx={isHere ? { color: '#273EAC' } : { color: 'white' }} />
      ) : null}
    </div>
  );

  const drawer = (
    <div className={classes.navbarContainer}>
      <List className={classes.navbarListItems}>
        <div className={classes.topMenuItem}>
          {stepsTop.map((stepLocation, index) => {
            const pathname =
              location.pathname === '/'
                ? 'website'
                : location.pathname.slice(1);

            return (
              <Linked
                to={`/${stepLocation}`}
                style={{ textDecoration: 'none' }}
                key={index}
              >
                <ListItem
                  className={[
                    classes.navbarItem,
                    pathname === stepLocation && classes.navbarActiveItem,
                  ].join(' ')}
                  button
                >
                  {iconStep(stepLocation, pathname === stepLocation)}
                  <ListItemText
                    className={classes.navbarItemText}
                    primary={friendlyStepsName[stepLocation]}
                    sx={
                      pathname === stepLocation
                        ? { '& span': { color: '#060640' } }
                        : { '& span': { color: 'white' } }
                    }
                  />
                </ListItem>
              </Linked>
            );
          })}
          {user?.role === Roles.USER ? (
            <ButtonBase
              onClick={() => {
                handleAddSignature();
              }}
            >
              <ListItem className={classes.navbarItem}>
                <AddCircleOutlineIcon
                  sx={{ color: 'white', marginRight: '5px' }}
                />
                <ListItemText
                  className={classes.navbarItemText}
                  primary={'Add Signature'}
                  sx={{ '& span': { color: 'white' } }}
                />
              </ListItem>
            </ButtonBase>
          ) : null}
        </div>

        {user?.isAdmin ? (
          <LinkRouter to="/admin" style={{ textDecoration: 'none' }}>
            <ListItem
              className={[
                classes.navbarItem,
                location.pathname === '/admin' && classes.navbarActiveItem,
              ].join(' ')}
              sx={
                location.pathname === '/admin'
                  ? { '& span': { color: '#060640' } }
                  : { '& span': { color: 'white' } }
              }
              button
            >
              <AdminPanelSettingsOutlinedIcon
                className={classes.iconStep}
                style={location.pathname === '/admin' ? {} : { color: 'white' }}
              />
              <ListItemText
                className={classes.navbarItemText}
                primary={'Admin'}
              />
            </ListItem>
          </LinkRouter>
        ) : null}

        <div className={classes.botMenuItem}>
          {stepsBot.map((stepLocation, index) => {
            const pathname =
              location.pathname === '/'
                ? 'website'
                : location.pathname.slice(1);

            return (
              <Linked
                to={`/${stepLocation}`}
                style={{ textDecoration: 'none' }}
                key={index}
              >
                <ListItem
                  className={[
                    classes.navbarItem,
                    pathname === stepLocation && classes.navbarActiveItem,
                  ].join(' ')}
                  button
                >
                  {iconStep(stepLocation, pathname === stepLocation)}
                  <ListItemText
                    className={classes.navbarItemText}
                    primary={friendlyStepsName[stepLocation]}
                    sx={
                      pathname === stepLocation
                        ? { '& span': { color: '#060640' } }
                        : { '& span': { color: 'white' } }
                    }
                  />
                </ListItem>
              </Linked>
            );
          })}
        </div>
      </List>
    </div>
  );

  const drawerHeader = (
    <>
      <div className={classes.logoContainer}>
        <LinkRouter to="/">
          <Box component="img" src="/static/icons/Logo.svg" />
        </LinkRouter>
      </div>

      <div className={classes.userContainer}>
        <Avatar
          alt="avatar"
          src={user?.profilePictureUrl}
          aria-label="avatar"
          className={classes.avatar}
        />
        <ButtonBase
          aria-controls={openT ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openT ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '14px',
              color: '#060640',
            }}
          >
            {user?.firstName} {user?.lastName}
          </Typography>

          <ArrowDropDownIcon />
        </ButtonBase>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={openT}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: '2000' }}
      >
        <MenuItem onClick={handleClose}>
          <LinkRouter
            to={'/settings'}
            style={{
              textDecoration: 'none',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontFamily: 'Poppins',
              fontSize: '16px',
            }}
          >
            <AccountCircleIcon style={{ marginRight: '2px' }} />
            {t('pages.Settings.userSettings')}
          </LinkRouter>
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(logout());
            setAnchorEl(null);
          }}
          style={{
            textDecoration: 'none',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: 'Poppins',
            fontSize: '16px',
          }}
        >
          <LogoutIcon />
          {t('common.logout')}
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <Page title="Kudeo">
        <div className={classes.pageContainer}>
          <div className={classes.headerLayoutNavbar}>{drawerHeader}</div>
          <div className={classes.loggedLayoutContainer}>
            <div className={classes.loggedLayoutNavbar}>{drawer}</div>
            <div className={`${classes.loggedLayoutBox}`}>
              <div className={classes.loggedLayoutContent}>{children}</div>
              <UserEdition />
              <SignatureEdition />
              <SignatureSelector />
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};
