import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import tagReducer, { tagsAdapter } from '../slices/tag';
import signatureReducer, { signaturesAdapter } from '../slices/signature';

/* Reducers */
const rootReducer = combineReducers({
  auth: authReducer,
  tags: tagReducer,
  signatures: signatureReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const tagsSelector = tagsAdapter.getSelectors<RootState>(
  (state) => state.tags,
);

export const signatureSelector = signaturesAdapter.getSelectors<RootState>(
  (state) => state.signatures,
);
