import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { User } from 'src/slices/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Flex } from './Flex';

const useStyles = createUseStyles({
  profileContainer: {
    color: 'rgb(97, 97, 97)',
    padding: theme.spacing(3),
    backgroundColor: 'rgb(250, 250, 250)',
  },
  profileHeader: {
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
    },
  },
});

interface Props {
  user: User;
  addAdmin: (userId: string) => void;
  undoAdminAccountFunction: (userId: string) => void;
  adminDeleteAccount: (userId: string) => void;
  activateAccount: (userId: string) => void;
  changeUserEmailAndPassword: (userId: string) => void;
}

const ProfileCard: React.FC<Props> = ({
  user,
  addAdmin,
  adminDeleteAccount,
  activateAccount,
  changeUserEmailAndPassword,
  undoAdminAccountFunction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userRegisterDate = new Date(user.createdAt ?? Date.now());
  const userLastConnectionDate = user.stats.lastConnection
    ? new Date(user.stats.lastConnection)
    : userRegisterDate;

  return (
    <>
      <Card className={classes.profileContainer}>
        <CardHeader
          className={classes.profileHeader}
          avatar={
            <Avatar
              src={user.profilePictureUrl}
              style={{ marginBottom: theme.spacing(1), width: 60, height: 60 }}
            />
          }
          title={<div>{user.firstName + ' ' + user.lastName}</div>}
          subheader={<div>{user.email}</div>}
          action={
            user.isAdmin ? (
              <>
                <AdminPanelSettingsIcon color="primary" />
              </>
            ) : (
              <></>
            )
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {(user.company || user.position) && (
              <Grid item xs={12}>
                <Flex style={{ alignItems: 'center' }}>
                  <div>
                    <Typography component="div" variant="h5">
                      {user.company ?? ''}
                    </Typography>
                    <Typography variant="body2" color="#637381" component="div">
                      {user.position ?? ''}
                    </Typography>
                  </div>
                </Flex>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography component="div" variant="h5">
                {t('pages.Admin.stats')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="div" variant="h5">
                {t('pages.Admin.userInfo')}
              </Typography>
              <div>
                {"Date d'inscription : " +
                  userRegisterDate.toLocaleDateString() +
                  '-' +
                  userRegisterDate.toLocaleTimeString()}
              </div>
              <div>
                {'Dernière connexion : ' +
                  userLastConnectionDate.toLocaleDateString() +
                  '-' +
                  userLastConnectionDate.toLocaleTimeString()}
              </div>
              <div>
                {'Nombre de connexion : ' +
                  (user.stats?.numberOfConnections ?? 0)}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'right',
            alignItems: 'stretch',
            display: 'block',
          }}
        >
          {!user.isActiveAccount ? (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ManageAccountsIcon />}
              onClick={() => activateAccount(user._id)}
            >
              {t('pages.Admin.activateAccount')}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="error"
              startIcon={<ManageAccountsIcon />}
              onClick={() => activateAccount(user._id)}
            >
              {t('pages.Admin.deactivateAccount')}
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => changeUserEmailAndPassword(user._id)}
          >
            {'Modifier le user'}
          </Button>

          <Button
            variant="outlined"
            color={user.isAdmin ? 'error' : 'primary'}
            startIcon={<AdminPanelSettingsIcon />}
            onClick={() => {
              if (user.isAdmin) {
                undoAdminAccountFunction(user._id);
              } else {
                addAdmin(user._id);
              }
            }}
          >
            {user?.isAdmin
              ? t('pages.Admin.removeAdmin')
              : t('pages.Admin.addAdmin')}
          </Button>

          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteForeverIcon />}
            onClick={() => adminDeleteAccount(user._id)}
          >
            {t('common.delete')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ProfileCard;
