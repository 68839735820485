import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { apiRequest } from 'src/helpers/api';
import { addNewSignature, Signature } from './signature';

export interface Tag {
  _id: string;
  name: string;
  signature: Signature;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface CreateTagDto {
  name: string;
}

export interface UpdateTagDto {
  id: string;
  name: string;
}

export interface DeleteTagDto {
  idTag: string;
  idUser: string;
}

export const fetchAllTags = createAsyncThunk('tag/fetchAllTag', async () => {
  const result = await apiRequest<Tag[]>('GET', '/tag');
  return result;
});

export const fetchTagById = createAsyncThunk(
  'tag/fetchTagById',
  async (tagId: string) => {
    const result: Tag = await apiRequest<Tag>('GET', `/tag/${tagId}`);
    return result;
  },
);

export const addNewTag = createAsyncThunk(
  'tag/addTag',
  async (createTagDto: CreateTagDto) => {
    const results: Tag = await apiRequest<Tag>(
      'POST',
      '/tag/addTag',
      undefined,
      {
        ...createTagDto,
      },
    );
    return results;
  },
);

export const updateTag = createAsyncThunk(
  'tag/updateTag',
  async (updateTagDto: UpdateTagDto) => {
    const result = await apiRequest<Tag>('POST', '/tag/update', undefined, {
      id: updateTagDto.id,
      name: updateTagDto.name,
    });

    return result;
  },
);

export const deleteTag = createAsyncThunk(
  'tag/deleteTag',
  async (deleteTagDto: DeleteTagDto) => {
    await apiRequest<Tag>('DELETE', '/tag/delete', undefined, {
      ...deleteTagDto,
    });

    return deleteTagDto.idTag;
  },
);

export const tagsAdapter = createEntityAdapter<Tag, EntityId>({
  selectId: (tag: Tag) => tag._id,
});

const upsertTagReducer = (
  state: EntityState<Tag, EntityId>,
  action: PayloadAction<Tag>,
) => {
  tagsAdapter.upsertOne(state, action.payload);
};

const removeTagReducer = (
  state: EntityState<Tag, EntityId>,
  action: PayloadAction<EntityId>,
) => {
  tagsAdapter.removeOne(state, action.payload);
};

const removeManyTagsReducer = (
  state: EntityState<Tag, EntityId>,
  action: PayloadAction<EntityId[]>,
) => {
  tagsAdapter.removeMany(state, action.payload);
};

const tagSlice = createSlice({
  name: 'tag',
  initialState: tagsAdapter.getInitialState(),
  reducers: {
    upsertTag: upsertTagReducer,
    removeTag: removeTagReducer,
    removeManyTags: removeManyTagsReducer,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTags.fulfilled, tagsAdapter.setAll)
      .addCase(fetchTagById.fulfilled, (state, { payload }) =>
        tagsAdapter.setAll(state, [payload]),
      )
      .addCase(addNewTag.fulfilled, (state, { payload }) =>
        tagsAdapter.addOne(state, payload),
      )
      .addCase(updateTag.fulfilled, upsertTagReducer)
      .addCase(deleteTag.fulfilled, removeTagReducer)
      .addCase(addNewSignature.fulfilled, (state, action) => {
        const { tag } = action.payload;

        // Ajoute ou met à jour le tag dans l'état
        tagsAdapter.upsertOne(state, tag);
      });
  },
});

export const { upsertTag, removeTag, removeManyTags } = tagSlice.actions;

// Exporter le reducer pour l'inclure dans le store
export default tagSlice.reducer;
