import React from 'react';

interface Props {
  user: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    profilePictureUrl?: string;
    companyLogo?: string;
    email: string;
    company?: string;
    position?: string;
    website?: string;
    facebook?: string;
    linkedin?: string;
    twitter?: string;
    address?: string;
  };
}

const SignatureModel3Email: React.FC<Props> = ({ user }) => {
  return (
    <table
      width="100%"
      style={{
        width: '450px',
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        height: '180px',
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              width: '120px',
              textAlign: 'center',
            }}
          >
            <img
              src={user?.profilePictureUrl ?? ''}
              alt={user?.firstName}
              style={{ width: '120px', height: '120px', borderRadius: '50%' }}
            />
          </td>
          <td style={{ paddingLeft: '16px' }}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#6d836b',
                      }}
                    >
                      {user.firstName} {user.lastName}
                    </span>{' '}
                    -{' '}
                    <span style={{ fontSize: '14px', color: '#999' }}>
                      {user.position}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: '14px', paddingTop: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {user.phoneNumber}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '8px' }}>
                    <span
                      style={{
                        display: 'inline-block',
                        backgroundColor: '#fff',
                        color: '#6d836b',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginRight: '8px',
                      }}
                    >
                      {user.company}
                    </span>
                    <a
                      href={user.website}
                      target="_blank"
                      style={{
                        fontSize: '16px',
                        color: '#6d836b',
                        textDecoration: 'none',
                      }}
                      rel="noopener noreferrer"
                    >
                      {user.website}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <a
                        href={user.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://sign.kudeo.co/facebook-logo.png"
                          alt="Facebook"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                      <a
                        href={user.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: '10px' }}
                      >
                        <img
                          src="https://sign.kudeo.co/linkedin-logo.png"
                          alt="LinkedIn"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                      <a
                        href={user.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: '10px' }}
                      >
                        <img
                          src="https://sign.kudeo.co/x-logo.png"
                          alt="X"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SignatureModel3Email;
