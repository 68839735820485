import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useAppSelector } from 'src/hooks/store';
import SignatureModel1 from 'src/constants/signature/signatureModel1';
import SignatureModel2 from 'src/constants/signature/signatureModel2';
import SignatureModel3 from 'src/constants/signature/signatureModel3';
import SignatureModel4 from 'src/constants/signature/signatureModel4';
import { Tag } from 'src/slices/tag';
import ReactDOMServer from 'react-dom/server';

const useStyles = createUseStyles({
  signaturesSelector: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '100%',
  },
  signatureSelectorTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '14px',
    color: '#060640',
  },
  signaturesListSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '25px',
    height: 'fit-content',
  },
  signatureCardSelector: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

interface Props {
  tag: Tag;
  checked: boolean[];
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
  setBody: React.Dispatch<React.SetStateAction<string>>;
}

const SignatureSelectorBody: React.FC<Props> = ({
  tag,
  checked,
  setChecked,
  setBody,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  const userPlaceholder = {
    firstName: '{firstName}',
    lastName: '{lastName}',
    phoneNumber: '{phoneNumber}',
    profilePictureUrl: '{profilePictureUrl}',
    companyLogo: '{companyLogo}',
    email: '{email}',
    company: '{company}',
    position: '{position}',
    website: '{website}',
    facebook: '{facebook}',
    linkedin: '{linkedin}',
    twitter: '{twitter}',
    address: '{address}',
  };

  useEffect(() => {
    if (tag.signature && tag.signature.model) {
      const modelIndex = tag.signature.model - 1;
      const newChecked = checked.map((item, index) =>
        index === modelIndex ? true : false,
      );

      setChecked(newChecked);
    }
  }, [tag.signature]);

  useEffect(() => {
    const trueIndex = checked.findIndex((value) => value === true);
    let htmlString = '';

    if (trueIndex !== -1 && user) {
      // Choisir le composant correct en fonction de l'index
      switch (trueIndex) {
        case 0:
          htmlString = ReactDOMServer.renderToString(
            <SignatureModel1 user={userPlaceholder} />,
          );
          break;
        case 1:
          htmlString = ReactDOMServer.renderToString(
            <SignatureModel2 user={userPlaceholder} />,
          );
          break;
        case 2:
          htmlString = ReactDOMServer.renderToString(
            <SignatureModel3 user={userPlaceholder} />,
          );
          break;
        case 3:
          htmlString = ReactDOMServer.renderToString(
            <SignatureModel4 user={userPlaceholder} />,
          );
          break;
        default:
          break;
      }
      setBody(htmlString);
    }
  }, [checked]);

  const preview = () => {
    const signatureModels = [
      SignatureModel1,
      SignatureModel2,
      SignatureModel3,
      SignatureModel4,
    ];

    return (
      user &&
      signatureModels.map((SignatureModel, index) => (
        <div key={index} className={classes.previewContainer}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon style={{ color: '#273EAC' }} />}
            checkedIcon={
              <RadioButtonCheckedIcon style={{ color: '#273EAC' }} />
            }
            onChange={() => {
              const newChecked = [false, false, false, false];
              newChecked[index] = true;
              setChecked(newChecked);
            }}
            checked={checked[index]}
          />
          <SignatureModel user={user} />
        </div>
      ))
    );
  };

  return (
    <div className={classes.signaturesListSelectorContainer}>
      <div className={classes.signaturesSelector}>
        <Typography className={classes.signatureSelectorTitle}>
          {t('pages.Signature.select')}
        </Typography>

        <div className={classes.signatureCardSelector}>{preview()}</div>
      </div>
    </div>
  );
};

export default SignatureSelectorBody;
